<div class="layout-wrapper">
  <div class="grid grid-cols-1 xl:grid-cols-2">
    <div class="image-container"></div>
    <div class="message-container flex flex-1 flex-col">
      <h1>
        {{ "page_not_found.title" | translate }}
      </h1>

      <p [innerHTML]="'page_not_found.text' | translate"></p>
    </div>
  </div>
</div>
