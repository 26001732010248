<div class="display-product-container">
    <image-popover [image]="pImageUrl || pThumbnailUrl" [thumbnail]="pThumbnail || pThumbnailUrl" [thumbnailBase64]="pThumbnail ? true : false"
        [thumbnailContentType]="pThumbnailContentType" [status]="pStatus">
    </image-popover>
    <div class="display-product-container__product-info-container">
        <a tabIndex="-1" class="display-product-container__product-link link line-clamp-1" [href]="pLink" target="_blank">
            {{isTranslated ? (pName | translatedValue) : pName}}
        </a>
        <div class="display-product-container__product-ean">
            {{pEan}}
        </div>
    </div>
</div>