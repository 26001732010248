<div class="language-text-wrapper" [formGroup]="fgInternal">
  <div class="language-text-container" [formArrayName]="for">
    <div
      class="language-text relative"
      *ngFor="let field of formArray.controls; let i = index"
      [formGroupName]="i"
    >
      <span
        class="language-prefix required-{{
          getRequiredType(
            field.get('language')?.value,
            field.get('value')?.value
          )
        }}"
      >
        {{ field.get("language")?.value }}
      </span>

      <div
        class="language-suffix"
        [ngSwitch]="newline"
        [class.show-ai-translate-btn]="i > 0 && showAiTranslateButton"
      >
        <ng-container *ngSwitchDefault>
          <lxm-input
            class="flex-1"
            [readonly]="readonly"
            [inputClass]="inputClass"
            [inputWidth]="inputWidth"
            [inputId]="getInputId(i)"
            [maxlength]="maxlength"
            for="value"
          >
            <validation-errors
              [for]="for + '.' + field.get('language')?.value"
              [errorsFormGroup]="formGroup"
              aliasTo="value"
            ></validation-errors>
          </lxm-input>
          <ng-container
            [ngTemplateOutlet]="aiTranslateBtnTemplate"
            [ngTemplateOutletContext]="{ i: i, field: field }"
          ></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="true">
          <lxm-textarea
            class="flex-1"
            for="value"
            [readonly]="readonly"
            [maxlength]="maxlength"
            [quill]="quill"
            [inputId]="getInputId(i)"
            [style.width]="inputWidth"
          >
            <div textarea-actions>
              <ng-container
                [ngTemplateOutlet]="aiTranslateBtnTemplate"
                [ngTemplateOutletContext]="{ i: i, field: field }"
              ></ng-container>
            </div>
          </lxm-textarea>
          <validation-errors
            [for]="for + '.' + field.get('language')?.value"
            [errorsFormGroup]="formGroup"
            aliasTo="value"
          ></validation-errors>
        </ng-container>
      </div>

      <!-- <lxm-button
        type="transparent"
        (click)="aiTranslate(field.value.language, field.get('value'), i)"
        *ngIf="!readonly && i > 0 && primaryValue.value"
        [loading]="actionLoading['translateLang' + i]"
        [title]="'global.translate' | translate"
        label="AI"
      >
        <svg-icon svg-icon-right key="newProduct" fontSize="14px"> </svg-icon>
      </lxm-button> -->
    </div>
  </div>
</div>

<ng-template #aiTranslateBtnTemplate let-i="i" let-field="field">
  <lxm-button
    class="ai-translate-button"
    type="transparent"
    (click)="aiTranslate(field.value.language, field.get('value'), i)"
    [loading]="actionLoading['translateLang' + i]"
    [title]="'global.translate' | translate"
    padding="0 10px 0 6px">
    <svg-icon svg-icon-right key="aiTranslate" fontSize="17px"> </svg-icon>
  </lxm-button>
</ng-template>

<ng-template #upgradePlanBtnsTemplate>
  <div class="flex gap-4">
    <lxm-button
      type="beige"
      label="action.see_subscription_packages"
      (click)="onSubscriptionPackagesClick()"
    >
    </lxm-button>
    <lxm-button
      type="grey"
      label="action.ask_for_more_info"
      (click)="onAskMoreInfoClick()"
    >
    </lxm-button>
  </div>

</ng-template>