<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form">
  <span header-title>{{'cards.products.product_clone_dialog.title' | translate }}</span>
  <div body style="min-width:400px">
    <div class="flex flex-col" >
      <display-product class="dialog-display-product-sticky" *ngIf="data?.product?.productId" [data]="data.product"
        [isTranslated]="true" [productName]="data?.product?.productFullNameTranslated"></display-product>

      <form-field label="cards.products.product_clone_dialog.base_info">
        <div text>
          {{form.value.cloneBaseInfo | translateEnum:'Bool'}}
        </div>
      </form-field>

      <form-field label="cards.products.product_clone_dialog.product_Units.title">
        <slide-toggle toggle for="cloneUnits" (change)="saveLocalValue($event, 'cloneUnits')"></slide-toggle>
      </form-field>
      <info-array style="margin: -10px 0 20px 0;" prelude="cards.products.product_clone_dialog.product_Units.description"></info-array>

      <form-field label="cards.products.product_clone_dialog.price_info.title">
        <slide-toggle toggle for="clonePriceInfo" (change)="saveLocalValue($event, 'clonePriceInfo')"></slide-toggle>
      </form-field>
      <info-array style="margin: -10px 0 20px 0;" prelude="cards.products.product_clone_dialog.price_info.description"></info-array>

      <form-field label="cards.products.product_clone_dialog.assortment.title">
        <slide-toggle toggle for="cloneAssortments" (change)="saveLocalValue($event, 'cloneAssortments')">
        </slide-toggle>
      </form-field>
      <info-array style="margin: -10px 0 20px 0;" prelude="cards.products.product_clone_dialog.assortment.description"></info-array>

      <form-field label="cards.products.product_clone_dialog.images">
        <slide-toggle toggle for="cloneImages" (change)="saveLocalValue($event, 'cloneImages')"></slide-toggle>
      </form-field>
    </div>
  </div>
  <section footer>
    <lxm-button type="primary" class="ml10" label="cards.products.product_clone_dialog.clone" buttonType="submit"
      (click)="clone()" [loading]="actionLoading.clone" [disabled]="actionLoading.clone">
    </lxm-button>
    <lxm-button type="secondary" class="ml10" label="cards.products.product_clone_dialog.cancel"
      (click)="closeDialog()" [disabled]="actionLoading.clone">
    </lxm-button>
  </section>
</lxm-dialog>