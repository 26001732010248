<lxm-dialog [dialogRef]="dialogRef" [formGroup]="formGroup">
  <span header-title
    >{{ 'cards.products.conformity_declaration_dialog.title' | translate
    }}</span
  >

  <div body>
    <p
      class="mb30 mt15 font-text text-sm font-normal text-dirtyPurple"
      [innerHTML]="'cards.products.conformity_declaration_dialog.info_text' | translate"
    ></p>

    <div class="row">
      <div class="col-12 no-padding">
        <form-field
          dialog
          label="cards.products.conformity_declaration_dialog.label.declaration_format"
        >
          <!-- <div class="flex items-center gap-2">
            <svg-icon key="excel" fontSize="16px"></svg-icon>
            {{'global.excel' | translate}}
          </div> -->

          <lxm-select
            select
            for="declarationType"
            (change)="saveLocalValue($event, 'declarationType')"
            [options]="formatOptions"
            [clearable]="false"
            value="id"
            labelIconField="icon"
          >
          </lxm-select>
        </form-field>

        <form-field
          dialog
          label="cards.products.conformity_declaration_dialog.label.language"
        >
          <lxm-select
            select
            for="language"
            [options]="languageOptions"
            (change)="saveLocalValue($event, 'language')"
            [clearable]="false"
            labelIconField="iso3"
            labelIconFallback="global"
            labelIconClass="flag-icon"
          >
          </lxm-select>
        </form-field>

        <form-field
          dialog
          label="cards.products.conformity_declaration_dialog.label.include_product_expiration"
        >
          <slide-toggle
            toggle
            for="includeProductExpiration"
            (change)="saveLocalValue($event, 'includeProductExpiration')"
          >
          </slide-toggle>
        </form-field>

        <form-field
          *ngIf="formGroup.value.declarationType === ConformityDeclarationType.Pdf"
          dialog
          for="recipients"
          label="cards.products.conformity_declaration_dialog.label.recipients"
        >
          <lxm-select
            groupBy="companyName"
            [options]="contactOptions"
            for="recipients"
            class="w-full"
            [multiple]="true"
            labelField="value"
            additionalField="companyName"
            [searchable]="true"
          ></lxm-select>

          <validation-errors for="recipients"></validation-errors>
        </form-field>

        <card-message *ngIf="showTooManyProductsWarning"
          class="mt10"
          type="warning"
          [html]="'cards.products.conformity_declaration_dialog.too_many_products_warning_text' | translate"
        >
        </card-message>
      </div>
    </div>
  </div>

  <section footer>
    <ng-container [ngSwitch]="formGroup.value.declarationType">
      <ng-container *ngSwitchCase="ConformityDeclarationType.Excel">
        <lxm-button
          type="primary"
          label="action.save"
          (click)="save()"
          [loading]="actionLoading.save"
        ></lxm-button>
      </ng-container>
      <ng-container *ngSwitchCase="ConformityDeclarationType.Pdf">
        <lxm-button
          type="primary"
          [label]="formGroup.value.recipients?.length ? 'action.save_and_send' : 'action.save'"
          [loading]="actionLoading.save"
          [disabled]="showTooManyProductsWarning"
          (click)="save()"
        ></lxm-button>
      </ng-container>
    </ng-container>

    <lxm-button
      type="secondary"
      label="action.cancel"
      (click)="closeDialog()"
    ></lxm-button>
  </section>
</lxm-dialog>
