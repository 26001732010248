<ng-container [formGroup]="formGroup">
  <div class="row">
    <div class="col-12 col-md-12">
      <div class="flex mb15 w-full">
        <div class="flex flex-1 flex-row items-center gap-2">
          <span class="dialog-column-title">
            {{
              "cards.offer.assortment_and_prices_dialog.title_prices" | translate
            }}
          </span>
          <description-popover
            [description]="'cards.offer.assortment_and_prices_dialog.title_prices_description' | translate"
          ></description-popover>
        </div>
      </div>
      <form-field
        dialog
        label="cards.offer.assortment_and_prices_dialog.label.valid"
        [required]="!readonly"
      >
        <div combined class="flex" style="align-items: center">
          <lxm-datepicker
            class="dialog-input-fixed-width-1"
            for="priceSchemeValidFrom"
            [readonly]="readonly"
            [min]="nextDay"
          ></lxm-datepicker>
          <ng-container *ngIf="isCampaign">
            <div class="ml10 mr10">-</div>
            <lxm-datepicker
              class="dialog-input-fixed-width-1"
              for="priceSchemeValidTo"
              [readonly]="readonly"
              [min]="nextDay"
            ></lxm-datepicker>
          </ng-container>
        </div>
        <validation-errors for="priceSchemeValidFrom"></validation-errors>
        <validation-errors
          for="priceSchemeValidTo"
          aliasTo="priceSchemeValidTo"
        ></validation-errors>
      </form-field>
    </div>
  </div>

  <div class="separator mb20 mt20"></div>

  <div class="row">
    <div class="col-12 col-md-12">
      <currency-price-table
        [readonly]="readonly"
        [formGroup]="formGroup"
        for="retailPrices"
        style="width: 100%; max-width: 100%"
        [currencies]="currencies"
        [defaultCurrency]="defaultCurrency"
        [markupMethod]="markupMethod"
        [isBuyInPriceChangeable]="isBuyInPriceChangeable"
        [digits]="3"
      >
      </currency-price-table>
    </div>
  </div>

  <ng-container
    *ngIf="
      readonly ? formGroup.value.priceGroups?.length : priceGroupOptions?.length
    "
  >
    <div class="separator mb20 mt20"></div>

    <div class="row">
      <div class="col-12 col-md-12">
        <price-group-table
          [readonly]="readonly"
          [formGroup]="formGroup"
          for="priceGroups"
          [priceGroupOptions]="priceGroupOptions"
          [currencies]="currencies"
          [defaultCurrency]="defaultCurrency"
          [markupMethod]="markupMethod"
          style="width: 100%; max-width: 100%"
        >
        </price-group-table>
      </div>
    </div>
  </ng-container>
</ng-container>
