import {
  Component,
  OnInit,
  Optional,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  HostBinding,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { Observable, Subject, Subscription } from "rxjs";
import { ViewEncapsulation } from "@angular/core";
import { FormGroup, ControlContainer } from "@angular/forms";
import { NgSelectComponent, NgSelectConfig } from "@ng-select/ng-select";
import { HttpClient } from "@angular/common/http";
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
} from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { CompareWithFn } from "@ng-select/ng-select/lib/ng-select.component";
import { TranslatedValuePipe } from "src/app/pipes";

import svgIcons from "src/assets/svg/svg-icons";

@Component({
  selector: "lxm-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TranslatedValuePipe],
})
export class SelectComponent implements OnInit, OnChanges {
  @HostBinding("style.width") @Input() public width = "100%";
  @HostBinding("class.readonly") @Input() public readonly = false;
  @HostBinding("class.disabled") @Input() public disabled = false;

  @ViewChild("select", { static: false }) public select: NgSelectComponent;

  @Output() change: EventEmitter<any> = new EventEmitter();

  @Input() close: Observable<void>;

  @Input() public options: any[] = [];
  @Input() public url: string;
  @Input() public acData: () => any | any;
  @Input() public acLabelFormat: (item: any) => string;
  @Input() public formGroup: FormGroup;
  @Input() public for: string;
  @Input() public placeholder: string;
  @Input() public searchable = false;
  @Input() public clearable = true;
  @Input() public multiple = false;
  @Input() public selectAllOption = false;
  @Input() public maxSelectedItems: number;
  @Input() public labelField: string = "name";
  @Input() public labelIcon: string | undefined;
  @Input() public labelIconField: string | undefined;
  @Input() public labelIconFallback: string | undefined;
  @Input() public labelIconClass: string | undefined;
  @Input() public compareField: string;
  @Input() public additionalField: string;
  @Input() public dropdownPosition: string = "auto";
  @Input() public closeOnSelect = true;
  @Input() public underLabel: string;
  @Input() public value: string;
  @Input() public virtualScroll = false;
  @Input() public hideSelected = false;
  @Input() public isTranslated = false;
  @Input() public sortByLabel = false;
  @Input() public sortByFn: (a: any, b: any) => number;
  @Input() public disableOption: (i: any) => boolean;
  @Input() public addTag: boolean | ((term: string) => any | Promise<any>) =
    false;
  @Input() public addTagText: string;
  @Input() public groupBy: any;
  @Input() public minTermLength: number = 2;
  @Input() public loading = false;
  @Output() public loadingChange = new EventEmitter<boolean>();
  @Input() public labelFn: (i: any) => string;
  @Input() public classFn: (i: any) => string;

  @Input() public compareWith: CompareWithFn = (a: any, b: any): boolean => {
    const field = this.value || this.compareField;
    if (field && (a.hasOwnProperty(field) || b.hasOwnProperty(field))) {
      return a[field] === b[field] || a[field] === b || a === b[field];
    }
    return JSON.stringify(a) === JSON.stringify(b);
  };

  public notFoundTranslated: string;
  public typeToSearchTranslated: string;
  public loadingTranslated: string;

  public finalizedOptions: any[];
  public filteredOptions = [];

  // Turn on virtual scroll after specified options count
  // Note: Makes performance worse with small datasets
  public virutalScrollTurnOnPoint = 150;

  public selected: any;
  public overlay = false;
  public hasValue: boolean;

  public acRes: Observable<any>;
  public acSubject = new Subject();

  private _langChangeSubscription: Subscription;
  private _valueChangeSubscription: Subscription;
  private _closeSubscription: Subscription;

  constructor(
    private _http: HttpClient,
    private _translateService: TranslateService,
    private _translatedValuePipe: TranslatedValuePipe,
    private _cd: ChangeDetectorRef,
    @Optional() private _controlContainer: ControlContainer,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      if (!changes.options.firstChange) {
        this._finalizeOptions();
      }
    }
  }

  public get ref() {
    return this.formGroup.controls[this.for];
  }

  public get isAutocomplete(): boolean {
    return this.url ? true : false;
  }

  public getItemClass(item: any) {
    if (this.classFn) {
      return this.classFn(item);
    }

    return null;
  }

  public getItemValue(item: any) {
    if (this.disableOption) {
      item.disabled = this.disableOption(item);
    }

    if (this.labelFn) {
      return this.labelFn(item);
    }

    if (this.isTranslated) {
      if (item[this.labelField]) {
        return this._translatedValuePipe.transform(item[this.labelField]);
      }
      return "";
    }

    return item[this.labelField];
  }

  public getItemIcon(item: any) {
    if (!this.labelIconField || !item[this.labelIconField]) {
      return;
    }

    const key = item[this.labelIconField]?.toLowerCase();

    if (key) {
      if (svgIcons[key]) {
        return key;
      }
    }

    if (this.labelIconFallback) {
      if (svgIcons[this.labelIconFallback]) {
        return this.labelIconFallback;
      }
    }

    return;
  }

  public onSelectAll(): void {
    let toSelect = this.options;
    if (this.value) {
      toSelect = toSelect.map((o) => o[this.value]);
    }
    this.formGroup.get(this.for).patchValue(toSelect);
  }

  public onClearAll(): void {
    this.formGroup.get(this.for).patchValue([]);
  }

  public getAdditionalValue(item: any): any {
    return new Function("_", "return _." + this.additionalField)(item);
  }

  public _finalizeOptions(): void {
    let finalizedOptions = this._getTranslatedOptions(
      this.options,
      this.labelField,
      this.isTranslated,
    );
    if (this.sortByLabel) {
      const fn =
        this.sortByFn ||
        ((a: any, b: any) =>
          this.getItemValue(a) > this.getItemValue(b) ? 1 : -1);
      finalizedOptions = finalizedOptions?.sort(fn);
    }

    this.finalizedOptions = finalizedOptions?.filter((x) =>
      this.getItemValue(x),
    );

    this.finalizedOptions = this.finalizedOptions || [];
    this.filteredOptions = [...this.finalizedOptions];

    if (this.finalizedOptions?.length > this.virutalScrollTurnOnPoint) {
      this.virtualScroll = true;
    } else {
      this.virtualScroll = false;
    }
  }

  private _getTranslatedOptions(
    items: any[],
    labelField: string,
    isTranslated: boolean,
  ): any[] {
    if (!isTranslated) {
      return items?.map((x) => {
        const clone = { ...x };

        const label = x[labelField];
        clone[labelField] =
          label == null ? "" : this._translateService.instant(label);
        return clone;
      });
    }
    return items;
  }

  private _translateLabels(): void {
    this._translateService
      .get("components.select.type_to_search_text", {
        number: this.minTermLength,
      })
      .subscribe((translated: string) => {
        this.typeToSearchTranslated = translated;
      });
    this._translateService
      .get("components.select.not_found_text")
      .subscribe((translated: string) => {
        this.notFoundTranslated = translated;
      });
    this._translateService
      .get("components.select.loading")
      .subscribe((translated: string) => {
        this.loadingTranslated = translated;
      });
  }

  public onChange(value: any): void {
    if (!this.url && !this.multiple) {
      this.select.blur();
    }
    this.change.emit(value);
  }

  public onOpen(e): void {
    this.overlay = true;
  }

  public onClose(e): void {
    this.overlay = false;

    if (this.url) {
      this.options = [];
    }
  }

  public ngOnInit() {
    this._translateLabels();
    this._finalizeOptions();
    this._langChangeSubscription =
      this._translateService.onLangChange.subscribe(() => {
        this._translateLabels();
        this._finalizeOptions();
      });

    if (
      this._controlContainer &&
      this._controlContainer.control instanceof FormGroup
    ) {
      this.formGroup = <FormGroup>this._controlContainer.control;

      if (this.for) {
        const ref = this.formGroup.get(this.for);

        if (ref) {
          this.selected = ref.value;
          this._updateHasValue(this.selected);

          this._valueChangeSubscription = ref.valueChanges.subscribe((x) => {
            this._updateHasValue(x);
          });
        } else {
          console.error(`No FormGroup reference for ${this.for}.`);
        }
      }
    }

    if (this.url) {
      const _this = this;
      this.acRes = this.acSubject.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        map((term) => {
          if (term === null) {
            this.loading = false;
            this.loadingChange.emit(false);
            return null;
          }

          this.loading = true;
          this.loadingChange.emit(true);
          if (_this.acData) {
            const data =
              typeof _this.acData === "function"
                ? _this.acData()
                : _this.acData;
            return _this._http.post<any[]>(_this.url + term, data);
          } else {
            return _this._http.get<any[]>(_this.url + term);
          }
        }),
      );

      this.acRes.subscribe((o) => {
        if (o === null) {
          this.loading = false;
          this.loadingChange.emit(false);
          return;
        }

        o.subscribe((res: any[]) => {
          if (_this.acLabelFormat) {
            _this.options = res.map((x) =>
              Object.assign(x, { [_this.labelField]: _this.acLabelFormat(x) }),
            );
          } else {
            _this.options = res;
          }
          this.loading = false;
          this.loadingChange.emit(false);
          this._cd.markForCheck();
        });
      });
    }

    if (this.close) {
      this._closeSubscription = this.close.subscribe(() => this.select.close());
    }
  }

  ngAfterViewInit() {
    if (this.select && !this.groupBy && this.compareWith) {
      this.select.compareWith = this.compareWith;
    }
  }

  public search = (term: string, item: any) => {
    if (this.url) {
      return true;
    }

    if (this.additionalField) {
      return (
        this.getItemValue(item).toLowerCase().indexOf(term.toLowerCase()) >
          -1 ||
        this.getAdditionalValue(item)
          .toLowerCase()
          .indexOf(term.toLowerCase()) > -1
      );
    }

    var val = this.getItemValue(item);
    if (!val) {
      return false;
    }

    return val.toLowerCase().indexOf(term.toLowerCase()) > -1;
  };

  public find(search: { term: string; items: any[] }) {
    if (!this.url) {
      return;
    }

    if (search.term?.length < this.minTermLength) {
      this.options = [];
      return;
    }

    this.acSubject.next(search.term);
  }

  private _updateHasValue(x: any) {
    const isArray = (o) => o instanceof Array;
    this.hasValue =
      x !== undefined &&
      x !== null &&
      ((isArray(x) && x.length > 0) || (!isArray(x) && x !== ""));
  }

  ngOnDestroy() {
    if (this._closeSubscription) {
      this._closeSubscription.unsubscribe();
    }
    if (this._langChangeSubscription) {
      this._langChangeSubscription.unsubscribe();
    }
    if (this._valueChangeSubscription) {
      this._valueChangeSubscription.unsubscribe();
    }
  }
}
