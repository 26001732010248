<div class="card-message-wrapper">
  <span class="card-message-indicator"></span>
  <div class="flex flex-col flex-1 justify-center">
    <div class="card-message-text">
      <span [innerHTML]="html" *ngIf="html"></span>
      <ng-content></ng-content>
      <ng-container *ngFor="let message of messages">
        {{message | translate}}
      </ng-container>
    </div>
  </div>
</div>
