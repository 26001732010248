<card [fullScreen]="state.isFullScreen">
  <span header-title
    >{{'cards.products.products_supplier.title' | translate}}</span
  >
  <div header-actions>
    <ng-container *nonVisitor>
      <div
        class="selected-products"
        [tooltip]="productsTemplate"
        tooltipApplyClass="stripped"
        *ngIf="selection.length > 0"
      >
        <span class="selected-count"> {{(selection.length)}} </span>
      </div>

      <ng-template #productsTemplate>
        <div class="selected-products-wrapper" id="selected-products-wrapper">
          <div class="align-center mb10 flex flex-row justify-between">
            <span class="align-center flex flex-row">
              <h2>
                {{'cards.products.products_supplier.selected_products.selected_products'
                | translate}}
              </h2>
              <span class="ml5"> ({{selection.length}}) </span>
            </span>
            <lxm-button
              type="transparent"
              icon="trash"
              padding="8px 0"
              (click)="emptySelectedProducts()"
            >
            </lxm-button>
          </div>
          <table class="selected-products-table">
            <tr class="header-row">
              <th class="select-column"></th>
              <th class="image-column"></th>
              <th
                class="name-column"
                [innerHTML]="'cards.products.products_supplier.table.full_name' |
                  translate"
              ></th>
              <th
                class="ean-column"
                [innerHTML]="'cards.products.products_supplier.table.ean' | translate"
              ></th>
            </tr>
            <tr class="product-row" *ngFor="let selection of selection">
              <td class="select-column">
                <mat-checkbox
                  class="selected-checkbox"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? toggleSelection(selection) : null"
                  [checked]="isProductSelected(selection)"
                >
                </mat-checkbox>
              </td>
              <td class="image-column">
                <image-popover
                  class="selected-image"
                  appendTo="#selected-products-wrapper"
                  type="product"
                  [popperPositionFixed]="false"
                  [image]="selection.imageThumbUrl"
                  [thumbnail]="selection.thumbnail"
                  [thumbnailBase64]="true"
                  [thumbnailContentType]="selection.thumbnailContentType"
                  [status]="selection.status"
                  [description]="getImageDescription(row)"
                  [isActiveBottom]="selection.activeViaOffer"
                >
                </image-popover>
              </td>
              <td class="name-column">
                <a
                  class="link"
                  href="/products/{{selection.id}}"
                  target="_blank"
                >
                  {{selection.fullName}}
                </a>
              </td>
              <td class="ean-column">
                <div class="triple-rows">
                  <!-- <span class="cell-row"> &nbsp; </span> -->
                  <div class="cell-row">{{selection.ean}}</div>
                  <div class="cell-row alt-text">
                    {{selection.supplierCode}}
                  </div>
                </div>
                <!-- {{selection.ean}}</td> -->
              </td>
            </tr>
          </table>
        </div>
      </ng-template>
    </ng-container>

    <!-- <lxm-button
      icon="excel"
      type="beige"
      [title]="'action.download_xls' | translate"
      (click)="export()"
      *ngIf="hasProducts"
    >
    </lxm-button> -->

    <ng-container *requiredRights="[UserAction.ManageSalesOrders]">
      <lxm-button
        [disabled]="!selection.length"
        type="green"
        icon="plus-dark"
        label="action.add_to_order"
        (click)="createSalesOrderFromSelection()"
      ></lxm-button>
    </ng-container>

    <lxm-button
      *requiredRights="[UserAction.ManageSupplierOffers]"
      (click)="createOffer()"
      [disabled]="!selection.length"
      label="cards.products.product_base_info.action.create_offer"
      icon="plus-dark"
      type="green"
    >
    </lxm-button>

    <lxm-button
      type="beige"
      icon="plus-dark"
      label="cards.products.products_supplier.add_product"
      (click)="newProduct()"
      *requiredRights="[UserAction.ManageProducts]"
    >
    </lxm-button>

    <!-- MASS ACTIONS MENU -->
    <ng-container *nonVisitor>
      <!-- <ng-container *ngIf="selection.length"> -->
      <lxm-button
        type="grey"
        label="action.other_actions"
        [popper]="menuContent"
        popperTrigger="click"
        popperPlacement="bottom"
        [popperPositionFixed]="true"
        [popperHideOnMouseLeave]="false"
        [popperHideOnClickOutside]="true"
        [class.open]="!menuContent.opacity ? false : true"
        popperApplyClass="dot-menu-content"
      >
        <svg-icon
          svg-icon-right
          key="monochromeArrowDownSmall2"
          fontSize="14px"
        ></svg-icon>
      </lxm-button>

      <!-- </ng-container> -->
    </ng-container>

    <!--  -->

    <!-- OLD DOT MENU <dot-menu [template]="optionsTemplate" [close]="closeDotMenu" *nonVisitor>
      <ng-template #optionsTemplate>
        <ul>
          <li
            *ngIf="formData?.canCreateConformityDeclaration"
            [class.disabled]="!selection.length"
            (click)="openConformityDeclarationDialog()"
          >
            <lxm-button
              icon="plus-dark"
              label="action.create_conformity_declaration"
            ></lxm-button>
          </li>
          <li
            *ngIf="appState.hasModule(LxmAppModule.Pdf)"
            [class.disabled]="!selection.length"
            (click)="openExportProductsDialog()"
          >
            <lxm-button icon="pdf" label="action.download_pdf"></lxm-button>
          </li>
          <li (click)="export()" *ngIf="hasProducts">
            <lxm-button icon="excel" label="action.download_xls"> </lxm-button>
          </li>
        </ul>
      </ng-template>
    </dot-menu> -->
  </div>

  <div body class="sticky-table">
    <div class="sticky-table-body overflow-x w-full">
      <mat-table
        [dataSource]="dataSource"
        matSort
        [@.disabled]="true"
        [zoomValue]="state.zoom"
      >
        <!-- Checkbox Column -->
        <ng-container matColumnDef="product_select" sticky>
          <mat-header-cell *matHeaderCellDef>
            <div class="f-1 align-center flex justify-center">
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="isAllSelected()"
                [indeterminate]="!isAllSelected()"
              >
              </mat-checkbox>
            </div>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let row"
            stop-propagation
            (click)="$event ? toggleSelection(row) : null"
          >
            <div class="f-1 align-center flex justify-center">
              <mat-checkbox
                class="table-checkbox"
                (click)="$event.stopPropagation()"
                (change)="$event ? toggleSelection(row) : null"
                [checked]="isProductSelected(row)"
              >
              </mat-checkbox>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Image Popover Column -->
        <ng-container matColumnDef="product_image" sticky>
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <image-popover
              [image]="row.imageThumbUrl"
              [thumbnail]="row.thumbnail"
              [thumbnailBase64]="true"
              [thumbnailContentType]="row.thumbnailContentType"
              [status]="row.status"
              [description]="getImageDescription(row)"
              [isActiveBottom]="row.activeViaOffer"
            ></image-popover>
          </mat-cell>
        </ng-container>

        <!-- Full Name Column -->
        <ng-container matColumnDef="product_name" sticky>
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{'cards.products.products_supplier.table.full_name' | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span class="flex overflow-ellipsis"> {{row.fullName}} </span>
          </mat-cell>
        </ng-container>

        <!-- EAN Column -->
        <ng-container matColumnDef="product_ean">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              <span
                [innerHTML]="'cards.products.products_supplier.table.ean' | translate"
              ></span>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <div class="triple-rows">
              <span class="cell-row"> &nbsp; </span>
              <div class="cell-row">{{row.ean}}</div>
              <div class="cell-row alt-text">{{row.supplierCode}}</div>
            </div>
          </mat-cell>
        </ng-container>

        <!-- Brand Column -->
        <ng-container matColumnDef="product_brand">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{'cards.products.products_supplier.table.brand' | translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.brand}} </mat-cell>
        </ng-container>

        <!-- Product Tree Column -->
        <ng-container matColumnDef="product_tree">
          <mat-header-cell *matHeaderCellDef>
            <div mat-sort-header>
              {{'cards.products.products_supplier.table.product_tree' |
              translate}}
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span> {{row.segment}} </span>
          </mat-cell>
        </ng-container>

        <ng-container
          *ngFor="let chain of formData.retailChains"
          [matColumnDef]="chain.id"
        >
          <mat-header-cell
            *matHeaderCellDef
            class="mat-column-product_chain"
            [title]="chain.name"
          >
            <div class="th-retail-chain">
              {{chain.shortName}}
              <div style="font-size: 10px; font-weight: normal">
                {{chain.country?.iso3 || '-'}}
              </div>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" class="mat-column-product_chain">
            <div class="flex flex-col">
              <supplier-product-price-info-popover
                [scheme]="getRetailPriceScheme(row, chain.id)"
                [hasInvalidValues]="getRetailerHasInvalidValues(row, chain.id)"
              >
              </supplier-product-price-info-popover>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="table.columns"
          class="products-table-head"
        ></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: table.columns;"
          class="table-row-hover"
          routerLink="/products/{{row.id}}"
        ></mat-row>
      </mat-table>
    </div>

    <no-content
      heading="cards.products.no_products_heading"
      text="cards.products.no_products_text"
      image="no-products"
      *ngIf="!hasProducts"
    >
    </no-content>

    <data-table-footer
      [localStorageKey]="LOCAL_STORAGE_KEY"
      [isLoading]="isLoading"
      (paginator)="hookPaginator($event)"
      [search]="search"
      (onSearch)="onSearch($event)"
      [(isFullScreen)]="state.isFullScreen"
      (onToggleFullScreen)="state.isFullScreen = $event"
      [(zoom)]="state.zoom"
      (onLoad)="onLoad()"
    >
    </data-table-footer>
  </div>
</card>

<popper-content #menuContent>
  <ul (click)="menuContent.hide()">
    <!-- <li *requiredRights="[UserAction.ManageProducts]" (click)="newProduct()">
      <lxm-button
        icon="plus-dark"
        label="cards.products.products_supplier.add_product"
      >
      </lxm-button>
    </li>

    <li
      *requiredRights="[UserAction.ManageSalesOrders]"
      (click)="createSalesOrderFromSelection()"
    >
      <lxm-button
        icon="plus-dark"
        label="action.create_sales_order"
      ></lxm-button>
    </li> -->
    <!-- <li
              *requiredRights="[UserAction.ManageSupplierOffers]"
              (click)="createOffer()"
            >
              <lxm-button
                label="cards.products.product_base_info.action.create_offer"
                icon="plus-dark"
              >
              </lxm-button>
            </li> -->
    <li
      *ngIf="formData?.canCreateConformityDeclaration"
      [class.disabled]="!selection.length"
      (click)="openConformityDeclarationDialog(selection)"
    >
      <lxm-button
        icon="plus-dark"
        label="action.create_conformity_declaration"
      ></lxm-button>
    </li>
    <li
      *ngIf="appState.hasModule(LxmAppModule.Pdf)"
      [class.disabled]="!selection.length"
      (click)="openExportProductsDialog()"
    >
      <lxm-button
        icon="pdf"
        label="action.download_pdf_selected_products"
      ></lxm-button>
    </li>
    <li
      (click)="export()"
      *ngIf="hasProducts"
      [class.disabled]="!selection.length"
    >
      <lxm-button icon="excel" label="action.download_xls_selected_products">
      </lxm-button>
    </li>
  </ul>
</popper-content>
