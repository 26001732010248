<table [formGroup]="fgInternal" class="w-full table-fixed">
    <thead>
      <tr>
        <th class="col-name">
          {{'components.product_procurement_list.product_name' | translate}}
        </th>
        <th class="col-amount">
          {{'components.product_procurement_list.amount' | translate}}
        </th>
        <th class="col-comment">
          {{'components.product_procurement_list.comment' | translate}}
        </th>
        <th class="col-controls">

        </th>
      </tr>
    </thead>
    <tbody [formArrayName]="for">
  
      <tr *ngFor="let field of formArray.controls; let i = index" class="procurement-item-row" [formGroupName]="i" [class.deleted]="field.value.deleted" style="position: relative;">
        <td class="col-name">
          <lxm-input input class="f-1" for="name" placeholder="components.product_procurement_list.product_name" [readonly]="readonly || field.value.deleted" (blur)="blur.emit($event)">
            <validation-errors [for]="for + '[' + i + '].name'" [errorsFormGroup]="formGroup"></validation-errors>
          </lxm-input>
        </td>
  
        <td class="col-price">
          <lxm-input for="amount"  placeholder="components.product_procurement_list.amount" class="f-1" [readonly]="readonly || field.value.deleted" (blur)="blur.emit($event)">
              <validation-errors [for]="for + '[' + i + '].amount'" [errorsFormGroup]="formGroup" aliasTo="productAmount"></validation-errors>
            </lxm-input>
        </td>
  
        <td class="col-description">
            <lxm-input for="comment" placeholder="components.product_procurement_list.comment" class="f-1" [readonly]="readonly || field.value.deleted" (blur)="blur.emit($event)">
                <validation-errors [for]="for + '[' + i + '].comment'" [errorsFormGroup]="formGroup"></validation-errors>
            </lxm-input>
        </td>
  
        <td class="col-controls" *ngIf="!readonly">
          <lxm-button type="grey" icon="trash" padding="8px" (click)="removeRow(i)"></lxm-button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="row mt10" *ngIf="!readonly">
    <lxm-button type="transparent" label="components.product_procurement_list.add_new_product" padding="8px 0" icon="plus-dark" (click)="addRow()"></lxm-button>
  </div>
