<div class="lxm-message-container">

  <div class="message-wrapper">

    <span class="message-indicator" *ngIf="data.showIndicator"></span>

    <div class="message-content">

      <h4 class="message-title" *ngIf="data.title">
        {{data.title | translate}}
      </h4>

      <div class="message-data">

        {{data.message | translate: data.args}}

        <div *ngIf="data.template" [innerHTML]="data.template"></div>
        <div *ngIf="data.templateKey" [innerHTML]="data.templateKey"></div>

        <!--<ng-container *ngTemplateOutlet="template"></ng-container>-->

        <div *ngIf="data.messages">
          <span *ngFor="let message of data.messages">
            {{message | translate}}
          </span>
        </div>

      </div>

      <div *ngIf="data.buttonAction" class="action-container">
        <lxm-button type="beige" [icon]="data.buttonIcon" [label]="data.buttonText" (click)="data.buttonAction()"></lxm-button>
      </div>

      <div *ngIf="data.actionsTemplate" class="action-container">
        <ng-container *ngTemplateOutlet="data.actionsTemplate"></ng-container>
      </div>

    </div>

  </div>
  
  <div class="flex flex-col">
    <lxm-button class="message-close" type="transparent" icon="crossmark-sm" (click)="dismiss()"></lxm-button>
  </div>


</div>