export enum ProductField {
  FullName = 1001,
  ShortName = 1002,
  CountryOfOrigin = 1003,
  Brand = 1004,
  SubBrand = 1005,
  Color = 1006,
  Model = 1007,
  CustomsCode = 1008,
  AdditionalSizeInfo = 1009,
  Manufacturer = 1010,
  Importer = 1011,
  IsConsignmentProduct = 1012,
  HasExciseCertificate = 1013,
  OrderUnit = 1014,
  SalesUnit = 1015,
  AverageWeightInGrams = 1016,
  NetContent = 1017,
  ContentUnit = 1018,
  DrainedWeightInKg = 1019,
  HasLotNumber = 1020,
  HygieneCertificateRequired = 1021,
  MarkingPriceRequired = 1022,
  Gs1Segment = 1023,
  Segment = 1024,
  BaseUnitCodes = 1025,
  Status = 1026,
  Supplier = 1027,
  CountryOfProduction = 1028,
  CountryOfPackaging = 1029,

  Description = 2001,
  InstructionsForUse = 2002,
  ExtraParameter1 = 2003,
  ExtraParameter2 = 2004,
  ExtraParameter3 = 2005,
  ExtraParameter4 = 2006,
  ExtraParameter5 = 2007,
  ExtraParameter6 = 2008,
  ExtraParameter7 = 2009,
  ExtraParameter8 = 2010,
  ExtraParameter9 = 2011,
  ExtraParameter10 = 2012,
  Awards = 2013,
  Attributes = 2014,
  Warnings = 2015,
  Tags = 2016,
  TechnicalName = 2017,
  FoodSafetyRecommendation = 2018,

  PaoMarkingInMonths = 3001,
  StorageConditions = 3002,
  ShelfLifeAfterOpeningInHours = 3003,
  StorageTemperatureControlRequired = 3004,
  StorageMinTemp = 3005,
  StorageMaxTemp = 3006,
  RetailerExpirations = 3007,
  ShelfLifeMarking = 3008,
  StorageInfo = 3009,

  EthicalMarking = 4001,
  AlcoholFree = 4002,
  SoyBased = 4003,
  LabelLanguages = 4004,
  EcoFriendly = 4005,
  HealthyOption = 4006,
  AllergySealOfApproval = 4007,
  Sensitive = 4008,
  Odorless = 4009,
  Natural = 4010,
  GmoContent = 4011,
  AdditiveFree = 4012,
  SuitableForVegans = 4013,
  SuitableForVegetarians = 4014,
  LactoseContentReduced = 4015,
  LactoseFree = 4016,
  DairyFree = 4017,
  DairyType = 4018,
  ReducedEnergyFood = 4019,
  LowCalorie = 4020,
  CalorieFree = 4021,
  LowGluten = 4022,
  GlutenFree = 4023,
  SourceOfFiber = 4024,
  HighFiber = 4025,
  SourceOfProtein = 4026,
  HighProtein = 4027,
  ReducedSugarFood = 4028,
  LowSugar = 4029,
  SugarFree = 4030,
  NoAddedSugar = 4031,
  LowSodium = 4032,
  VeryLowSodium = 4033,
  SodiumFree = 4034,
  NoAddedSodium = 4035,
  HighInMonounsaturatedFattyAcid = 4036,
  HighInPolyunsaturatedFattyAcid = 4037,
  HighInUnsaturatedFattyAcid = 4038,
  ReducedSaturatedFat = 4039,
  LowSaturatedFat = 4040,
  SaturatedFatFree = 4041,
  LowFat = 4042,
  FatFree = 4043,
  LightOrLite = 4044,
  SourceOfOmega3 = 4045,
  Omega3Rich = 4046,
  Ingredients = 4047,
  AllergenFree = 4048,
  Energy_kJ = 4049,
  Energy_kcal = 4050,
  TotalFat_g = 4051,
  SaturatedFat_g = 4052,
  MonounsaturatedFat_g = 4053,
  PolyunsaturatedFat_g = 4054,
  TotalCarbohydrate_g = 4055,
  TotalSugars_g = 4056,
  SugarAlcohol_g = 4057,
  Starch_g = 4058,
  DietaryFiber_g = 4059,
  Protein_g = 4060,
  Sodium_g = 4061,
  VitaminA_mcg = 4062,
  VitaminB6_mg = 4063,
  VitaminB12_mcg = 4064,
  VitaminC_mg = 4065,
  VitaminD_mcg = 4066,
  VitaminE_mg = 4067,
  VitaminK_mcg = 4068,
  Biotin_mcg = 4069,
  Fluoride_mg = 4070,
  FolicAcid_mcg = 4071,
  Phosphorus_mg = 4072,
  Iodine_mcg = 4073,
  Potassium_mg = 4074,
  Calcium_mg = 4075,
  Chromium_mcg = 4076,
  Magnesium_mg = 4077,
  Manganese_mg = 4078,
  Molybdenum_mcg = 4079,
  Niacin_mg = 4080,
  PantothenicAcid_mg = 4081,
  Iron_mg = 4082,
  Riboflavin_mg = 4083,
  Selenium_mcg = 4084,
  Thiamin_mg = 4085,
  Zinc_mg = 4086,
  Copper_mg = 4087,
  ProductAllergens = 4088,
  EthicalMarkings = 4089,
  GrainFree = 4090,
  ParabenFree = 4091,
  EnrichedWithProtein = 4092,
  EnrichedWithVitamins = 4093,
  YeastFree = 4094,
  NoAddedYeast = 4095,
  EggFree = 4096,
  MeatContentIncreased = 4097,
  Nitrite = 4098,
  PhosphorusPentoxide = 4099,

  IrradiationType = 4101,
  IdentificationMarking = 4102,
  MicrobiologicalRequirements = 4103,

  TypeOfAlcohol = 5001,
  AlcoholContent = 5002,
  AlcoholLicenseNumber = 5003,
  AlcoholLicenseDate = 5004,
  AlcoholCountryOfOrigin = 5005,
  Winery = 5006,
  WineRegionOfOrigin = 5007,
  AlcoholDescription = 5008,
  WineColor = 5009,
  WineCharacteristics = 5010,
  WineIntensity = 5011,
  WineTaste = 5012,
  WineGrapes = 5013,
  WineFoodSuggestions = 5014,
  AlcoholServingTemperature = 5015,

  ScientificNameOfFish = 6001,
  FishingArea = 6002,
  StateOfFish = 6003,
  FishTrim = 6004,

  IsElectronicsDevice = 7001,
  HasIntegratedBattery = 7002,
  IntegratedBatteryWeight = 7003,
  BatteryType = 7004,

  RaisedCountry = 8001,
  SlaughteredCountry = 8002,
  CountryWhereProductWasCut = 8003,

  ProductUnits = 9000,

  MovieMediaFormat = 10000,
  MovieDvdRegion = 10001,
  MovieAspectRatio = 10002,
  MovieDuration = 10003,
  MovieGenres = 10004,
  MovieYear = 10005,
  MovieReleaseYear = 10006,
  MovieProductionCountries = 10007,
  MovieProductionCompany = 10008,
  MovieContentRatings = 10009,
  MovieFormats = 10010,
  MovieAudio = 10011,
  MovieActors = 10012,

  MusicFormat = 10100,
  MusicGenres = 10101,
  MusicDiscAmount = 10102,
  MusicIsOnParentAgreement = 10103,
  MusicYear = 10104,
  MusicReleaseYear = 10105,
  MusicPublisher = 10106,
  MusicTrackLists = 10107,

  BookReleaseYear = 10200,
  BookFormat = 10201,
  BookLanguage = 10202,
  BookPageAmount = 10203,
  BookPublisher = 10204,
  BookEdition = 10205,
  BookRoles = 10206,

  GameFormat = 10300,
  GameAge = 10301,
  GamePlayers = 10302,
  GameDuration = 10303,
  GameGenres = 10304,
  GameReleaseYear = 10305,
  GamePublisher = 10306,
  GameContentRatings = 10307,
  GamePegiRating = 10308,
  GameIsCe = 10309,
  GameIsInfant = 10310,

  MedicationAtcSegment = 11000,
  MedicationDosageForm = 11001,
  MedicationPrescriptionType = 11002,
  MedicationAddictionType = 11003,
  MedicationAdministrationMethods = 11004,
  MedicationRiskType = 11005,
  MedicationStrength = 11006,
  MedicationRiskDescription = 11007,
  MedicationHasSecurityElements = 11008
}

(ProductField as any).__typeName = 'ProductField';

const baseInfo = 'cards.products.product_base_info.label';

(ProductField as any).__altTranslationKeys = {
  [ProductField.FullName]: baseInfo + '.full_name',
  [ProductField.ShortName]: baseInfo + '.short_name',
  [ProductField.CountryOfOrigin]: baseInfo + '.origin',
  [ProductField.Brand]: baseInfo + '.brand',
  [ProductField.SubBrand]: baseInfo + '.subbrand',
  [ProductField.Color]: baseInfo + '.color',
  [ProductField.Model]: baseInfo + '.model',
  [ProductField.CustomsCode]: baseInfo + '.customs_code',
  [ProductField.AdditionalSizeInfo]: baseInfo + '.size_info',
  [ProductField.Manufacturer]: baseInfo + '.manufacturer',
  [ProductField.Importer]: baseInfo + '.importer',
  [ProductField.IsConsignmentProduct]: baseInfo + '.consignment',
  [ProductField.HasExciseCertificate]: baseInfo + '.excise_certificate',
  [ProductField.OrderUnit]: baseInfo + '.unit_order',
  [ProductField.SalesUnit]: baseInfo + '.unit_sales',
  [ProductField.AverageWeightInGrams]: baseInfo + '.content_avg',
  [ProductField.NetContent]: baseInfo + '.content_net',
  [ProductField.ContentUnit]: baseInfo + '.content_unit',
  [ProductField.DrainedWeightInKg]: baseInfo + '.content_drained',
  [ProductField.HasLotNumber]: baseInfo + '.has_lot_number',
  [ProductField.HygieneCertificateRequired]: baseInfo + '.hygiene_certificate_required',
  [ProductField.MarkingPriceRequired]: baseInfo + '.marking_price_required',
  [ProductField.Gs1Segment]: baseInfo + '.segment_gs1',
  [ProductField.Segment]: baseInfo + '.segment',
  [ProductField.BaseUnitCodes]: 'cards.products.product_base_info.codes.title',
  [ProductField.Status]: baseInfo + '.status',
  [ProductField.Supplier]: baseInfo + '.supplier',
  [ProductField.CountryOfProduction]: baseInfo + '.country_of_production',
  [ProductField.CountryOfPackaging]: baseInfo + '.country_of_packaging',

  [ProductField.Awards]: 'cards.products.product_additional_info_and_instructions.label.awards',
  [ProductField.Description]: 'cards.products.product_additional_info_and_instructions.label.description',
  [ProductField.InstructionsForUse]: 'cards.products.product_additional_info_and_instructions.label.instructions',
  [ProductField.ExtraParameter1]: 'cards.products.product_description_fields.label.description_field_1',
  [ProductField.ExtraParameter2]: 'cards.products.product_description_fields.label.description_field_2',
  [ProductField.ExtraParameter3]: 'cards.products.product_description_fields.label.description_field_3',
  [ProductField.ExtraParameter4]: 'cards.products.product_description_fields.label.description_field_4',
  [ProductField.ExtraParameter5]: 'cards.products.product_description_fields.label.description_field_5',
  [ProductField.ExtraParameter6]: 'cards.products.product_description_fields.label.description_field_6',
  [ProductField.ExtraParameter7]: 'cards.products.product_description_fields.label.description_field_7',
  [ProductField.ExtraParameter8]: 'cards.products.product_description_fields.label.description_field_8',
  [ProductField.ExtraParameter9]: 'cards.products.product_description_fields.label.description_field_9',
  [ProductField.ExtraParameter10]: 'cards.products.product_description_fields.label.description_field_10',
  [ProductField.Tags]: 'cards.products.product_description_fields.label.tags',
  [ProductField.TechnicalName]: 'cards.products.product_additional_info_and_instructions.label.technical_name',
  [ProductField.FoodSafetyRecommendation]: 'cards.products.product_additional_info_and_instructions.label.food_safety_recommendation',

  [ProductField.Attributes]: 'cards.products.product_attributes.label.product_attributes',

  [ProductField.PaoMarkingInMonths]: 'cards.products.product_storage_info.label.PAO',
  [ProductField.StorageConditions]: 'cards.products.product_storage_info.label.storage_conditions',
  [ProductField.ShelfLifeAfterOpeningInHours]: 'cards.products.product_storage_info.label.shelf_life_open',
  [ProductField.StorageTemperatureControlRequired]: 'cards.products.product_storage_info.label.temp_control_required',
  [ProductField.StorageMinTemp]: 'cards.products.product_storage_info.label.storage_temp_min',
  [ProductField.StorageMaxTemp]: 'cards.products.product_storage_info.label.storage_temp_max',
  [ProductField.RetailerExpirations]: 'cards.products.product_storage_info.label.retailer_expirations',
  [ProductField.ShelfLifeMarking]: 'cards.products.product_storage_info.label.shelf_life_marking',
  [ProductField.StorageInfo]: 'cards.products.product_storage_info.label.storage_info_clv',

  [ProductField.EthicalMarking]: 'cards.products.product_certificate_info_and_nutrition_info.label.ethicality',
  [ProductField.EthicalMarkings]: 'cards.products.product_certificate_info_and_nutrition_info.label.ethicality',
  [ProductField.AlcoholFree]: 'cards.products.product_certificate_info_and_nutrition_info.label.alcohol_free',
  [ProductField.GrainFree]: 'cards.products.product_certificate_info_and_nutrition_info.label.grain_free',
  [ProductField.SoyBased]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.soy_based',
  [ProductField.LabelLanguages]: 'cards.products.product_certificate_info_and_nutrition_info.label.label_languages',
  [ProductField.EcoFriendly]: 'cards.products.product_certificate_info_and_nutrition_info.label.eco_friendly',
  [ProductField.HealthyOption]: 'cards.products.product_certificate_info_and_nutrition_info.label.healthy_option',
  [ProductField.AllergySealOfApproval]: 'cards.products.product_certificate_info_and_nutrition_info.label.allergy_free',
  [ProductField.Sensitive]: 'cards.products.product_certificate_info_and_nutrition_info.label.sensitive',
  [ProductField.ParabenFree]: 'cards.products.product_certificate_info_and_nutrition_info.label.paraben_free',
  [ProductField.Odorless]: 'cards.products.product_certificate_info_and_nutrition_info.label.odorless',
  [ProductField.Natural]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.natural',
  [ProductField.GmoContent]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.gmo_content',
  [ProductField.AdditiveFree]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.additive_free',
  [ProductField.SuitableForVegans]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.vegan',
  [ProductField.SuitableForVegetarians]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.vegetarian',
  [ProductField.LactoseContentReduced]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.lactose_reduced',
  [ProductField.LactoseFree]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.lactose_free',
  [ProductField.DairyFree]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.dairy_free',
  [ProductField.DairyType]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.dairy_type',
  [ProductField.ReducedEnergyFood]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.calorie_reduced',
  [ProductField.LowCalorie]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.calorie_low',
  [ProductField.CalorieFree]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.calorie_free',
  [ProductField.LowGluten]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.gluten_low',
  [ProductField.GlutenFree]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.gluten_free',
  [ProductField.SourceOfFiber]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.fiber',
  [ProductField.HighFiber]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.fiber_high',
  [ProductField.SourceOfProtein]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.protein',
  [ProductField.HighProtein]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.protein_high',
  [ProductField.ReducedSugarFood]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sugar_reduced',
  [ProductField.LowSugar]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sugar_low',
  [ProductField.SugarFree]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sugar_free',
  [ProductField.NoAddedSugar]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sugar_not_added',
  [ProductField.LowSodium]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sodium_low',
  [ProductField.VeryLowSodium]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sodium_very_low',
  [ProductField.SodiumFree]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sodium_free',
  [ProductField.NoAddedSodium]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sodium_not_added',
  [ProductField.HighInMonounsaturatedFattyAcid]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.monounsaturated_fat_high',
  [ProductField.HighInPolyunsaturatedFattyAcid]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.polyunsaturated_fat_high',
  [ProductField.HighInUnsaturatedFattyAcid]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.unsaturated_fat_high',
  [ProductField.ReducedSaturatedFat]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.saturated_fat_reduced',
  [ProductField.LowSaturatedFat]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.saturated_fat_low',
  [ProductField.SaturatedFatFree]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.saturated_fat_free',
  [ProductField.LowFat]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.fat_low',
  [ProductField.FatFree]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.fat_free',
  [ProductField.LightOrLite]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.light',
  [ProductField.SourceOfOmega3]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.omega3',
  [ProductField.Omega3Rich]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.omega3_high',
  [ProductField.EnrichedWithProtein]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.enriched_with_protein',
  [ProductField.EnrichedWithVitamins]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.enriched_with_vitamins',
  [ProductField.YeastFree]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.yeast_free',
  [ProductField.NoAddedYeast]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.no_added_yeast',
  [ProductField.EggFree]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.egg_free',
  [ProductField.MeatContentIncreased]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.meat_content_increased',
  [ProductField.Ingredients]: 'cards.products.product_certificate_info_and_nutrition_info.label.ingredients',
  [ProductField.AllergenFree]: 'cards.products.product_certificate_info_and_nutrition_info.label.allergen_free',
  [ProductField.Energy_kJ]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_energy_kj',
  [ProductField.Energy_kcal]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_energy_kcal',
  [ProductField.TotalFat_g]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_total_fat',
  [ProductField.SaturatedFat_g]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_saturated_fat',
  [ProductField.MonounsaturatedFat_g]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_monounsaturated_fat',
  [ProductField.PolyunsaturatedFat_g]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_polyunsaturated_fat',
  [ProductField.TotalCarbohydrate_g]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_total_carbohydrate',
  [ProductField.TotalSugars_g]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_sugars',
  [ProductField.SugarAlcohol_g]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_polyols',
  [ProductField.Starch_g]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_starch',
  [ProductField.DietaryFiber_g]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_dietary_fiber',
  [ProductField.Protein_g]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_protein',
  [ProductField.Sodium_g]: 'cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_sodium',
  [ProductField.VitaminA_mcg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_vitamin_a',
  [ProductField.VitaminB6_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_vitamin_b6',
  [ProductField.VitaminB12_mcg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_vitamin_b12',
  [ProductField.VitaminC_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_vitamin_c',
  [ProductField.VitaminD_mcg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_vitamin_d',
  [ProductField.VitaminE_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_vitamin_e',
  [ProductField.VitaminK_mcg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_vitamin_k',
  [ProductField.Biotin_mcg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_biotin',
  [ProductField.Fluoride_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_fluoride',
  [ProductField.FolicAcid_mcg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_folic_acid',
  [ProductField.Phosphorus_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_phosphorus',
  [ProductField.Iodine_mcg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_iodine',
  [ProductField.Potassium_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_potassium',
  [ProductField.Calcium_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_kaltsium',
  [ProductField.Chromium_mcg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_chromium',
  [ProductField.Magnesium_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_magnesium',
  [ProductField.Manganese_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_manganese',
  [ProductField.Molybdenum_mcg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_molybdenum',
  [ProductField.Niacin_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_niacin',
  [ProductField.PantothenicAcid_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_pantothenicacid',
  [ProductField.Iron_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_iron',
  [ProductField.Riboflavin_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_riboflavin',
  [ProductField.Selenium_mcg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_selenium',
  [ProductField.Thiamin_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_thiamin',
  [ProductField.Zinc_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_zinc',
  [ProductField.Copper_mg]: 'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_copper',
  [ProductField.ProductAllergens]: 'cards.products.product_certificate_info_and_nutrition_info.label.allergens',
  [ProductField.Nitrite]: 'cards.products.product_certificate_info_and_nutrition_info.product_physico_chemical_indicators.label.nitrite',
  [ProductField.PhosphorusPentoxide]: 'cards.products.product_certificate_info_and_nutrition_info.product_physico_chemical_indicators.label.phosphorus_pentoxide',
  [ProductField.IrradiationType]: 'cards.products.product_certificate_info_and_nutrition_info.label.irradiation_type',
  [ProductField.IdentificationMarking]: 'cards.products.product_certificate_info_and_nutrition_info.label.identification_marking',
  [ProductField.MicrobiologicalRequirements]: 'cards.products.product_certificate_info_and_nutrition_info.label.microbiological_requirements',

  [ProductField.TypeOfAlcohol]: 'cards.products.product_alcohol.label.alcohol_type',
  [ProductField.AlcoholContent]: 'cards.products.product_alcohol.label.alcohol_content',
  [ProductField.AlcoholLicenseNumber]: 'cards.products.product_alcohol.label.alcohol_licence_number',
  [ProductField.AlcoholLicenseDate]: 'cards.products.product_alcohol.label.alcohol_licence_date',
  [ProductField.AlcoholCountryOfOrigin]: 'cards.products.product_alcohol.label.alcohol_origin',
  [ProductField.Winery]: 'cards.products.product_alcohol.label.wine_winery',
  [ProductField.WineRegionOfOrigin]: 'cards.products.product_alcohol.label.wine_region',
  [ProductField.AlcoholDescription]: 'cards.products.product_alcohol.label.alcohol_description',
  [ProductField.WineColor]: 'cards.products.product_alcohol.label.wine_color',
  [ProductField.WineCharacteristics]: 'cards.products.product_alcohol.label.wine_character',
  [ProductField.WineIntensity]: 'cards.products.product_alcohol.label.wine_intensity',
  [ProductField.WineTaste]: 'cards.products.product_alcohol.label.wine_taste',
  [ProductField.WineGrapes]: 'cards.products.product_alcohol.label.wine_grape_varieties',
  [ProductField.WineFoodSuggestions]: 'cards.products.product_alcohol.label.wine_food_suggestion',
  [ProductField.AlcoholServingTemperature]: 'cards.products.product_alcohol.label.alcohol_serving_temperature',

  [ProductField.ScientificNameOfFish]: 'cards.products.product_fish.label.scientific_name',
  [ProductField.FishingArea]: 'cards.products.product_fish.label.fishing_area',
  [ProductField.StateOfFish]: 'cards.products.product_fish.label.state_fish',
  [ProductField.FishTrim]: 'cards.products.product_fish.label.fish_trim',

  [ProductField.IsElectronicsDevice]: 'cards.products.product_electronics.label.is_electronics_device',
  [ProductField.HasIntegratedBattery]: 'cards.products.product_electronics.label.has_integrated_battery',
  [ProductField.IntegratedBatteryWeight]: 'cards.products.product_electronics.label.integrated_battery_weight',
  [ProductField.BatteryType]: 'cards.products.product_electronics.label.battery_type',

  [ProductField.RaisedCountry]: 'cards.products.product_meat.label.raised',
  [ProductField.SlaughteredCountry]: 'cards.products.product_meat.label.slaughtered',
  [ProductField.CountryWhereProductWasCut]: 'cards.products.product_meat.label.country_where_product_was_cut',

  [ProductField.ProductUnits]: 'cards.products.product_packaging.title',

  [ProductField.MovieMediaFormat]: 'cards.products.product_movie_info.label.media_format',
  [ProductField.MovieDvdRegion]: 'cards.products.product_movie_info.label.dvd_region',
  [ProductField.MovieAspectRatio]: 'cards.products.product_movie_info.label.aspect_ratio',
  [ProductField.MovieDuration]: 'cards.products.product_movie_info.label.duration',
  [ProductField.MovieGenres]: 'cards.products.product_movie_info.label.genres',
  [ProductField.MovieYear]: 'cards.products.product_movie_info.label.year',
  [ProductField.MovieReleaseYear]: 'cards.products.product_movie_info.label.release_year',
  [ProductField.MovieProductionCountries]: 'cards.products.product_movie_info.label.production_countries',
  [ProductField.MovieProductionCompany]: 'cards.products.product_movie_info.label.production_company',
  [ProductField.MovieContentRatings]: 'cards.products.product_movie_info.label.content_ratings',
  [ProductField.MovieFormats]: 'cards.products.product_movie_info.format_section.format',
  [ProductField.MovieAudio]: 'cards.products.product_movie_info.audio_section.title',
  [ProductField.MovieActors]: 'cards.products.product_movie_info.actors_section.title',

  [ProductField.MusicFormat]: 'cards.products.product_music_info.label.music_format',
  [ProductField.MusicGenres]: 'cards.products.product_music_info.label.genres',
  [ProductField.MusicDiscAmount]: 'cards.products.product_music_info.label.disc_amount',
  [ProductField.MusicIsOnParentAgreement]: 'cards.products.product_music_info.label.isOnParentAgreement',
  [ProductField.MusicYear]: 'cards.products.product_music_info.label.year',
  [ProductField.MusicReleaseYear]: 'cards.products.product_music_info.label.release_year',
  [ProductField.MusicPublisher]: 'cards.products.product_music_info.label.publisher',
  [ProductField.MusicTrackLists]: 'cards.products.product_music_info.track_section.section_title',

  [ProductField.BookReleaseYear]: 'cards.products.product_book_info.label.release_year',
  [ProductField.BookFormat]: 'cards.products.product_book_info.label.book_format',
  [ProductField.BookLanguage]: 'cards.products.product_book_info.label.language',
  [ProductField.BookPageAmount]: 'cards.products.product_book_info.label.page_amount',
  [ProductField.BookPublisher]: 'cards.products.product_book_info.label.publisher',
  [ProductField.BookEdition]: 'cards.products.product_book_info.label.edition',
  [ProductField.BookRoles]: 'cards.products.product_book_info.role_section.role',

  [ProductField.GameFormat]: 'cards.products.product_game_info.label.game_format',
  [ProductField.GameAge]: 'cards.products.product_game_info.label.age',
  [ProductField.GamePlayers]: 'cards.products.product_game_info.label.players',
  [ProductField.GameDuration]: 'cards.products.product_game_info.label.duration',
  [ProductField.GameGenres]: 'cards.products.product_game_info.label.genres',
  [ProductField.GameReleaseYear]: 'cards.products.product_game_info.label.release_year',
  [ProductField.GamePublisher]: 'cards.products.product_game_info.label.publisher',
  [ProductField.GameContentRatings]: 'cards.products.product_game_info.label.content_ratings',
  [ProductField.GamePegiRating]: 'cards.products.product_game_info.label.pegi_rating',
  [ProductField.GameIsCe]: 'cards.products.product_game_info.label.isCe',
  [ProductField.GameIsInfant]: 'cards.products.product_game_info.label.isInfant',

  [ProductField.MedicationAtcSegment]: 'cards.products.product_medication_info.label.atc_segment',
  [ProductField.MedicationDosageForm]: 'cards.products.product_medication_info.label.dosage_form',
  [ProductField.MedicationPrescriptionType]: 'cards.products.product_medication_info.label.prescription_type',
  [ProductField.MedicationAddictionType]: 'cards.products.product_medication_info.label.addiction_type',
  [ProductField.MedicationAdministrationMethods]: 'cards.products.product_medication_info.label.administration_method',
  [ProductField.MedicationStrength]: 'cards.products.product_medication_info.label.strength',
  [ProductField.MedicationRiskType]: 'cards.products.product_medication_info.label.risk_type',
  [ProductField.MedicationRiskDescription]: 'cards.products.product_medication_info.label.risk_description',
  [ProductField.MedicationHasSecurityElements]: 'cards.products.product_medication_info.label.has_security_elements'
};
