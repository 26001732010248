<card-expanding [formGroup]="form" [valid]="productValidity.getCardStatusValidType(cardSection)" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">
  <span header-title>{{ title | translate}}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <ng-container *ngIf="isOwnProduct">
      <ng-container *ngIf="!locked">
        <lxm-button *ngIf="!edit" type="beige" icon="edit" title="action.edit" (click)="toggleEdit()"></lxm-button>
        <lxm-button *ngIf="edit" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()"></lxm-button>
        <lxm-button *ngIf="edit" type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()" buttonType="submit"></lxm-button>
      </ng-container>
      <ng-container *ngIf="locked">
        {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} ({{ lockedAt }})
      </ng-container>
    </ng-container>
  </div>

  <div body>

    <div class="grid grid-cols-1 xl:grid-cols-2 w-full gap-4">
      <div class="flex flex-col">

        <form-field label="cards.products.product_meat.label.raised" [readonly]="!edit" for="raisedCountry" description="cards.products.product_meat.label_description.raised">
          <lxm-select select
            [readonly]="!edit"
            [options]="countryOptions"
            [isTranslated]="true"
            labelField="translatedName"
            [sortByLabel]="true"
            [searchable]="true"
            labelIconField="iso3"
            labelIconFallback="global"
            labelIconClass="flag-icon"
            for="raisedCountry">
          </lxm-select>
          <validation-errors for="raisedCountry"></validation-errors>
          <validation-errors for="raisedCountryId"></validation-errors>
        </form-field>

        <form-field
          [readonly]="!edit"
          label="cards.products.product_meat.label.country_where_product_was_cut"
          description="cards.products.product_meat.label_description.country_where_product_was_cut"
          for="countryWhereProductWasCut"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.CountryWhereProductWasCut, form.get('countryWhereProductWasCut').value) : null"
        >
          <lxm-select
            select
            [readonly]="!edit"
            [options]="countryOptions"
            [isTranslated]="true"
            labelField="translatedName"
            [sortByLabel]="true"
            [searchable]="true"
            labelIconField="iso3"
            labelIconFallback="global"
            labelIconClass="flag-icon"
            for="countryWhereProductWasCut">
          </lxm-select>
          <validation-errors for="countryWhereProductWasCut"></validation-errors>
          <validation-errors for="countryWhereProductWasCutId"></validation-errors>
        </form-field>

      </div>

      <div class="flex flex-col">

        <form-field label="cards.products.product_meat.label.slaughtered" [readonly]="!edit" for="slaughteredCountry" description="cards.products.product_meat.label_description.slaughtered">
          <lxm-select select
            [readonly]="!edit"
            [options]="countryOptions"
            [isTranslated]="true"
            labelField="translatedName"
            [sortByLabel]="true"
            [searchable]="true"
            labelIconField="iso3"
            labelIconFallback="global"
            labelIconClass="flag-icon"
            for="slaughteredCountry">
          </lxm-select>
          <validation-errors for="slaughteredCountry"></validation-errors>
          <validation-errors for="slaughteredCountryId"></validation-errors>
        </form-field>

      </div>

    </div>

  </div>

</card-expanding>
