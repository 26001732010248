<ng-container *nonVisitor>
  <buyer-offers-search-card *ngIf="offerKind == OfferKind.ReceivingOffer || offerKind == OfferKind.OfferRequest"
    [isOpen]="isSearchPanelOpen" 
    [localStorageKey]="searchPanelLocalStorageKey" 
    (search)="searchSubject.next($event)">
  </buyer-offers-search-card>
  <seller-offers-search-card *ngIf="offerKind == OfferKind.SupplierOffer"
    [isOpen]="isSearchPanelOpen" 
    [localStorageKey]="searchPanelLocalStorageKey" 
    (search)="searchSubject.next($event)">
  </seller-offers-search-card>
</ng-container>

<offers-list-card [search]="search"></offers-list-card>

