<card-expanding [formGroup]="form" [valid]="cardValidType" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">

  <span header-title>{{ title | translate}}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <ng-container *ngIf="isOwnProduct">
      <ng-container *ngIf="!locked">
        <lxm-button *ngIf="!edit" type="beige" icon="edit" title="action.edit" (click)="toggleEdit()"></lxm-button>
        <lxm-button *ngIf="edit" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()">
        </lxm-button>
        <lxm-button *ngIf="edit" type="beige" icon="save" label="action.save" [loading]="saveLoading"
          (click)="save()" buttonType="submit"></lxm-button>
      </ng-container>
      <ng-container *ngIf="locked">
        {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} {{ lockedAt | amTimeAgo }}
      </ng-container>
    </ng-container>
  </div>

  <div body>

    <card-section>
      
    <div class="row">
      <div class="col-6 no-padding">

        <form-field [readonly]="!edit"
          label="cards.products.product_certificate_info_and_nutrition_info.label.ethicality"
          description="cards.products.product_certificate_info_and_nutrition_info.label_description.ethicality"
          for="ethicalMarkingId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.EthicalMarking, form.get('ethicalMarkingId').value) : null">
          <lxm-select select for="ethicalMarkingId" value="id" labelField="value" [readonly]="!edit" [sortByLabel]="true" [searchable]="true" [multiple]="true"
            [options]="ethicalMarkings" class="w-full"></lxm-select>
          <validation-errors for="ethicalMarkingId"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_certificate_info_and_nutrition_info.label.eco_friendly"
          description="cards.products.product_certificate_info_and_nutrition_info.label_description.eco_friendly"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.EcoFriendly, form.get('ecoFriendly').value) : null">
          <slide-toggle toggle for="ecoFriendly" [readonly]="!edit"></slide-toggle>
          <validation-errors for="ecoFriendly"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_certificate_info_and_nutrition_info.label.healthy_option"
          description="cards.products.product_certificate_info_and_nutrition_info.label_description.healthy_option"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.HealthyOption, form.get('healthyOption').value) : null">
          <slide-toggle toggle for="healthyOption" [readonly]="!edit"></slide-toggle>
          <validation-errors for="healthyOption"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_certificate_info_and_nutrition_info.label.label_languages"
          description="cards.products.product_certificate_info_and_nutrition_info.label_description.label_languages"
          for="labelLanguages"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.LabelLanguages, form.get('labelLanguages').value?.length > 0) : null">
          <lxm-select select for="labelLanguages" [readonly]="!edit" labelField="name" [multiple]="true"
            [searchable]="true" [options]="selectedLabelLanguages" url="api/clvac/languages/" class="w-full">
          </lxm-select>
          <validation-errors for="labelLanguages"></validation-errors>
        </form-field>

      </div>

      <div class="col-6 no-padding">

        <form-field [readonly]="!edit"
          label="cards.products.product_certificate_info_and_nutrition_info.label.identification_marking"
          description="cards.products.product_certificate_info_and_nutrition_info.label_description.identification_marking"
          for="identificationMarkingId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.IdentificationMarking, form.get('identificationMarkingId').value) : null">
          <lxm-select select for="identificationMarkingId" value="id" labelField="value" [readonly]="!edit" [sortByLabel]="true" [searchable]="true"
            [options]="identificationMarkingOptions" class="w-full"></lxm-select>
          <validation-errors for="identificationMarkingId"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_certificate_info_and_nutrition_info.label.allergy_free"
          description="cards.products.product_certificate_info_and_nutrition_info.label_description.allergy_free"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.AllergySealOfApproval, form.get('allergySealOfApproval').value) : null">
          <slide-toggle toggle for="allergySealOfApproval" [readonly]="!edit"></slide-toggle>
          <validation-errors for="allergySealOfApproval"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_certificate_info_and_nutrition_info.label.sensitive"
          description="cards.products.product_certificate_info_and_nutrition_info.label_description.sensitive"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Sensitive, form.get('sensitive').value) : null">
          <slide-toggle toggle for="sensitive" [readonly]="!edit"></slide-toggle>
          <validation-errors for="sensitive"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_certificate_info_and_nutrition_info.label.paraben_free"
          description="cards.products.product_certificate_info_and_nutrition_info.label_description.paraben_free"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ParabenFree, form.get('parabenFree').value) : null">
          <slide-toggle toggle for="parabenFree" [readonly]="!edit"></slide-toggle>
          <validation-errors for="parabenFree"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit" label="cards.products.product_certificate_info_and_nutrition_info.label.odorless"
          description="cards.products.product_certificate_info_and_nutrition_info.label_description.odorless"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Odorless, form.get('odorless').value) : null">
          <slide-toggle toggle for="odorless" [readonly]="!edit"></slide-toggle>
          <validation-errors for="odorless"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_certificate_info_and_nutrition_info.label.alcohol_free"
          description="cards.products.product_certificate_info_and_nutrition_info.label_description.alcohol_free"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.AlcoholFree, form.get('alcoholFree').value) : null">
          <slide-toggle toggle for="alcoholFree" [readonly]="!edit"></slide-toggle>
          <validation-errors for="alcoholFree"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_certificate_info_and_nutrition_info.label.grain_free"
          description="cards.products.product_certificate_info_and_nutrition_info.label_description.grain_free"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.GrainFree, form.get('grainFree').value) : null"
          *ngIf="showGrainFree">
          <slide-toggle toggle for="grainFree" [readonly]="!edit"></slide-toggle>
          <validation-errors for="grainFree"></validation-errors>
        </form-field>

      </div>
    </div>

    <div class="separator"></div>

    <div class="row">
      <div class="col-12 no-padding">

        <form-field [readonly]="!edit"
          label="cards.products.product_certificate_info_and_nutrition_info.label.ingredients"
          description="cards.products.product_certificate_info_and_nutrition_info.label_description.ingredients"
          for="ingredients"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailerLanguageText(productField.Ingredients) : null">
          <language-text language-text for="ingredients" [readonly]="!edit" [newline]="true"
            [required]="productValidity.shouldValidate ? productValidity.getLanguageTextRequiredTypes(productField.Ingredients) : null">
          </language-text>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_certificate_info_and_nutrition_info.label.microbiological_requirements"
          description="cards.products.product_certificate_info_and_nutrition_info.label_description.microbiological_requirements"
          for="microbiologicalRequirementIds"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MicrobiologicalRequirements, form.get('microbiologicalRequirementIds').value) : null">
          <lxm-select select for="microbiologicalRequirementIds" value="id" labelField="value" [readonly]="!edit" [sortByLabel]="true" [searchable]="true" [multiple]="true"
            [options]="microbiologicalRequirementOptions" class="w-full"></lxm-select>
          <validation-errors for="microbiologicalRequirementIds"></validation-errors>
        </form-field>

        <form-field [readonly]="!edit"
          label="cards.products.product_certificate_info_and_nutrition_info.label.allergen_free"
          description="cards.products.product_certificate_info_and_nutrition_info.label_description.allergen_free"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.AllergenFree, form.get('allergenFree').value) : null">
          <slide-toggle toggle for="allergenFree" [readonly]="!edit"></slide-toggle>
          <validation-errors for="allergenFree"></validation-errors>
        </form-field>

      </div>

    </div>

  </card-section>

    <section-expanding [expanded]="true" class="mt0" [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ProductAllergens, form.get('allergens').value?.length > 0) : null">
      <span header-title>
        {{'cards.products.product_certificate_info_and_nutrition_info.product_allergens.title' | translate}}
      </span>
      <div body class="w-full overflow-x">
          <product-allergens-list for="allergens" [readonly]="!edit" [allergenOptions]="allergenOptions">
          </product-allergens-list>
      </div>
    </section-expanding>

    <div class="separator"></div>

    <section-expanding [expanded]="shouldExpandSections">
      <span header-title>
        {{'cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.title' | translate}}
      </span>
      <div body>

        <div class="row">
          <div class="col-6 no-padding">

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.natural"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.natural"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Natural, form.get('natural').value) : null">
              <slide-toggle toggle for="natural" [readonly]="!edit"></slide-toggle>
              <validation-errors for="natural"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.gmo_content"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.gmo_content"
              for="gmoContentId"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.GmoContent, form.get('gmoContentId').value) : null">
              <lxm-select select for="gmoContentId" class="w50p" style="min-width: 200px" value="id"
                labelField="translatedValue" [isTranslated]="true" [readonly]="!edit" [options]="gmoContentOptions">
              </lxm-select>
              <validation-errors for="gmoContentId"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.label.irradiation_type"
              description="cards.products.product_certificate_info_and_nutrition_info.label_description.irradiation_type"
              for="irradiationTypeId"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.IrradiationType, form.get('irradiationTypeId').value) : null">
              <lxm-select select for="irradiationTypeId" value="id" labelField="value" [readonly]="!edit" [sortByLabel]="true" [searchable]="true"
                [options]="irradiationTypeOptions" class="w-full"></lxm-select>
              <validation-errors for="irradiationTypeId"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.additive_free"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.additive_free"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.AdditiveFree, form.get('additiveFree').value) : null">
              <slide-toggle toggle for="additiveFree" [readonly]="!edit"></slide-toggle>
              <validation-errors for="additiveFree"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.enriched_with_protein"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.enriched_with_protein"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.EnrichedWithProtein, form.get('enrichedWithProtein').value) : null">
              <slide-toggle toggle for="enrichedWithProtein" [readonly]="!edit"></slide-toggle>
              <validation-errors for="enrichedWithProtein"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.enriched_with_vitamins"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.enriched_with_vitamins"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.EnrichedWithVitamins, form.get('enrichedWithVitamins').value) : null">
              <slide-toggle toggle for="enrichedWithVitamins" [readonly]="!edit"></slide-toggle>
              <validation-errors for="enrichedWithVitamins"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.soy_based"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.soy_based"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.SoyBased, form.get('soyBased').value) : null">
              <slide-toggle toggle for="soyBased" [readonly]="!edit"></slide-toggle>
              <validation-errors for="soyBased"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.yeast_free"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.yeast_free"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.YeastFree, form.get('yeastFree').value) : null">
              <slide-toggle toggle for="yeastFree" [readonly]="!edit"></slide-toggle>
              <validation-errors for="yeastFree"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.no_added_yeast"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.no_added_yeast"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.NoAddedYeast, form.get('noAddedYeast').value) : null">
              <slide-toggle toggle for="noAddedYeast" [readonly]="!edit"></slide-toggle>
              <validation-errors for="noAddedYeast"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.vegan"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.vegan"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.SuitableForVegans, form.get('suitableForVegans').value) : null">
              <slide-toggle toggle for="suitableForVegans" [readonly]="!edit"></slide-toggle>
              <validation-errors for="suitableForVegans"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.vegetarian"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.vegetarian"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.SuitableForVegetarians, form.get('suitableForVegetarians').value) : null">
              <slide-toggle toggle for="suitableForVegetarians" [readonly]="!edit"></slide-toggle>
              <validation-errors for="suitableForVegetarians"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.egg_free"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.egg_free"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.EggFree, form.get('eggFree').value) : null">
              <slide-toggle toggle for="eggFree" [readonly]="!edit"></slide-toggle>
              <validation-errors for="eggFree"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.lactose_reduced"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.lactose_reduced"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.LactoseContentReduced, form.get('lactoseContentReduced').value) : null">
              <slide-toggle toggle for="lactoseContentReduced" [readonly]="!edit"></slide-toggle>
              <validation-errors for="lactoseContentReduced"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.lactose_free"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.lactose_free"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.LactoseFree, form.get('lactoseFree').value) : null">
              <slide-toggle toggle for="lactoseFree" [readonly]="!edit"></slide-toggle>
              <validation-errors for="lactoseFree"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.dairy_free"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.dairy_free"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.DairyFree, form.get('dairyFree').value) : null">
              <slide-toggle toggle for="dairyFree" [readonly]="!edit"></slide-toggle>
              <validation-errors for="dairyFree"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.dairy_type"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.dairy_type"
              for="dairyTypeId"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.DairyType, form.get('dairyTypeId').value) : null">
              <lxm-select select class="w50p" for="dairyTypeId" [readonly]="!edit" [options]="dairyTypes" value="id"
                labelField="translatedValue" [isTranslated]="true"></lxm-select>
              <validation-errors for="dairyType"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.calorie_reduced"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.calorie_reduced"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ReducedEnergyFood, form.get('reducedEnergyFood').value) : null">
              <slide-toggle toggle for="reducedEnergyFood" [readonly]="!edit"></slide-toggle>
              <validation-errors for="reducedEnergyFood"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.calorie_low"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.calorie_low"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.LowCalorie, form.get('lowCalorie').value) : null">
              <slide-toggle toggle for="lowCalorie" [readonly]="!edit"></slide-toggle>
              <validation-errors for="lowCalorie"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.calorie_free"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.calorie_free"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.CalorieFree, form.get('calorieFree').value) : null">
              <slide-toggle toggle for="calorieFree" [readonly]="!edit"></slide-toggle>
              <validation-errors for="calorieFree"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.gluten_low"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.gluten_low"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.LowGluten, form.get('lowGluten').value) : null">
              <slide-toggle toggle for="lowGluten" [readonly]="!edit"></slide-toggle>
              <validation-errors for="lowGluten"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.gluten_free"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.gluten_free"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.GlutenFree, form.get('glutenFree').value) : null">
              <slide-toggle toggle for="glutenFree" [readonly]="!edit"></slide-toggle>
              <validation-errors for="glutenFree"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.fiber"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.fiber"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.SourceOfFiber, form.get('sourceOfFiber').value) : null">
              <slide-toggle toggle for="sourceOfFiber" [readonly]="!edit"></slide-toggle>
              <validation-errors for="sourceOfFiber"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.fiber_high"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.fiber_high"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.HighFiber, form.get('highFiber').value) : null">
              <slide-toggle toggle for="highFiber" [readonly]="!edit"></slide-toggle>
              <validation-errors for="highFiber"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.protein"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.protein"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.SourceOfProtein, form.get('sourceOfProtein').value) : null">
              <slide-toggle toggle for="sourceOfProtein" [readonly]="!edit"></slide-toggle>
              <validation-errors for="sourceOfProtein"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.protein_high"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.protein_high"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.HighProtein, form.get('highProtein').value) : null">
              <slide-toggle toggle for="highProtein" [readonly]="!edit"></slide-toggle>
              <validation-errors for="highProtein"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.meat_content_increased"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.meat_content_increased"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MeatContentIncreased, form.get('meatContentIncreased').value) : null">
              <slide-toggle toggle for="meatContentIncreased" [readonly]="!edit"></slide-toggle>
              <validation-errors for="meatContentIncreased"></validation-errors>
            </form-field>

          </div>
          <div class="col-6 no-padding">

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sugar_reduced"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.sugar_reduced"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ReducedSugarFood, form.get('reducedSugarFood').value) : null">
              <slide-toggle toggle for="reducedSugarFood" [readonly]="!edit"></slide-toggle>
              <validation-errors for="reducedSugarFood"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sugar_low"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.sugar_low"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.LowSugar, form.get('lowSugar').value) : null">
              <slide-toggle toggle for="lowSugar" [readonly]="!edit"></slide-toggle>
              <validation-errors for="lowSugar"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sugar_free"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.sugar_free"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.SugarFree, form.get('sugarFree').value) : null">
              <slide-toggle toggle for="sugarFree" [readonly]="!edit"></slide-toggle>
              <validation-errors for="sugarFree"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sugar_not_added"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.sugar_not_added"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.NoAddedSugar, form.get('noAddedSugar').value) : null">
              <slide-toggle toggle for="noAddedSugar" [readonly]="!edit"></slide-toggle>
              <validation-errors for="noAddedSugar"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sodium_low"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.sodium_low"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.LowSodium, form.get('lowSodium').value) : null">
              <slide-toggle toggle for="lowSodium" [readonly]="!edit"></slide-toggle>
              <validation-errors for="lowSodium"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sodium_very_low"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.sodium_very_low"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.VeryLowSodium, form.get('veryLowSodium').value) : null">
              <slide-toggle toggle for="veryLowSodium" [readonly]="!edit"></slide-toggle>
              <validation-errors for="veryLowSodium"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sodium_free"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.sodium_free"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.SodiumFree, form.get('sodiumFree').value) : null">
              <slide-toggle toggle for="sodiumFree" [readonly]="!edit"></slide-toggle>
              <validation-errors for="sodiumFree"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.sodium_not_added"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.sodium_not_added"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.NoAddedSodium, form.get('noAddedSodium').value) : null">
              <slide-toggle toggle for="noAddedSodium" [readonly]="!edit"></slide-toggle>
              <validation-errors for="noAddedSodium"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.monounsaturated_fat_high"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.monounsaturated_fat_high"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.HighInMonounsaturatedFattyAcid, form.get('highInMonounsaturatedFattyAcid').value) : null">
              <slide-toggle toggle for="highInMonounsaturatedFattyAcid" [readonly]="!edit"></slide-toggle>
              <validation-errors for="highInMonounsaturatedFattyAcid"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.polyunsaturated_fat_high"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.polyunsaturated_fat_high"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.HighInPolyunsaturatedFattyAcid, form.get('highInPolyunsaturatedFattyAcid').value) : null">
              <slide-toggle toggle for="highInPolyunsaturatedFattyAcid" [readonly]="!edit"></slide-toggle>
              <validation-errors for="highInPolyunsaturatedFattyAcid"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.unsaturated_fat_high"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.unsaturated_fat_high"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.HighInUnsaturatedFattyAcid, form.get('highInUnsaturatedFattyAcid').value) : null">
              <slide-toggle toggle for="highInUnsaturatedFattyAcid" [readonly]="!edit"></slide-toggle>
              <validation-errors for="highInUnsaturatedFattyAcid"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.saturated_fat_reduced"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.saturated_fat_reduced"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.ReducedSaturatedFat, form.get('reducedSaturatedFat').value) : null">
              <slide-toggle toggle for="reducedSaturatedFat" [readonly]="!edit"></slide-toggle>
              <validation-errors for="reducedSaturatedFat"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.saturated_fat_low"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.saturated_fat_low"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.LowSaturatedFat, form.get('lowSaturatedFat').value) : null">
              <slide-toggle toggle for="lowSaturatedFat" [readonly]="!edit"></slide-toggle>
              <validation-errors for="lowSaturatedFat"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.saturated_fat_free"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.saturated_fat_free"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.SaturatedFatFree, form.get('saturatedFatFree').value) : null">
              <slide-toggle toggle for="saturatedFatFree" [readonly]="!edit"></slide-toggle>
              <validation-errors for="saturatedFatFree"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.fat_low"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.fat_low"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.LowFat, form.get('lowFat').value) : null">
              <slide-toggle toggle for="lowFat" [readonly]="!edit"></slide-toggle>
              <validation-errors for="lowFat"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.fat_free"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.fat_free"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.FatFree, form.get('fatFree').value) : null">
              <slide-toggle toggle for="fatFree" [readonly]="!edit"></slide-toggle>
              <validation-errors for="fatFree"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.light"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.light"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.LightOrLite, form.get('lightOrLite').value) : null">
              <slide-toggle toggle for="lightOrLite" [readonly]="!edit"></slide-toggle>
              <validation-errors for="lightOrLite"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.omega3"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.omega3"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.SourceOfOmega3, form.get('sourceOfOmega3').value) : null">
              <slide-toggle toggle for="sourceOfOmega3" [readonly]="!edit"></slide-toggle>
              <validation-errors for="sourceOfOmega3"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label.omega3_high"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutritional_claims.label_description.omega3_high"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Omega3Rich, form.get('omega3Rich').value) : null">
              <slide-toggle toggle for="omega3Rich" [readonly]="!edit"></slide-toggle>
              <validation-errors for="omega3Rich"></validation-errors>
            </form-field>

          </div>
        </div>

      </div>

    </section-expanding>

    <section-expanding [expanded]="shouldExpandSections">
      <span header-title>{{'cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.title' |
        translate}}</span>
      <div body>
        <div class="row">
          <div class="col-6 no-padding">

            <form-field [readonly]="!edit" for="energy_kJ"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_energy_kj"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label_description.nutrition_energy_kj"
              type="input-text"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Energy_kJ, form.get('energy_kJ').value) : null">
              <validation-errors for="energy_kJ"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="energy_kcal"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_energy_kcal"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label_description.nutrition_energy_kcal"
              type="input-text"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Energy_kcal, form.get('energy_kcal').value) : null">
              <validation-errors for="energy_kcal"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="totalFat_g"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_total_fat"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label_description.nutrition_total_fat"
              type="input-text"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.TotalFat_g, form.get('totalFat_g').value) : null">
              <validation-errors for="totalFat_g"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="saturatedFat_g"
              label="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_saturated_fat"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label_description.nutrition_saturated_fat"
              type="input-text"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.SaturatedFat_g, form.get('saturatedFat_g').value) : null">
              <validation-errors for="saturatedFat_g"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="monounsaturatedFat_g" label="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_monounsaturated_fat" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label_description.nutrition_monounsaturated_fat"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MonounsaturatedFat_g, form.get('monounsaturatedFat_g').value) : null">
              <validation-errors for="monounsaturatedFat_g"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="polyunsaturatedFat_g" label="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_polyunsaturated_fat" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label_description.nutrition_polyunsaturated_fat"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.PolyunsaturatedFat_g, form.get('polyunsaturatedFat_g').value) : null">
              <validation-errors for="polyunsaturatedFat_g"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="totalCarbohydrate_g" label="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_total_carbohydrate" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label_description.nutrition_total_carbohydrate"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.TotalCarbohydrate_g, form.get('totalCarbohydrate_g').value) : null">
              <validation-errors for="totalCarbohydrate_g"></validation-errors>
            </form-field>

          </div>
          <div class="col-6 no-padding">

            <form-field [readonly]="!edit" for="totalSugars_g" label="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_sugars" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label_description.nutrition_sugars"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.TotalSugars_g, form.get('totalSugars_g').value) : null">
              <validation-errors for="totalSugars_g"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="sugarAlcohol_g" label="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_polyols" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label_description.nutrition_polyols"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.SugarAlcohol_g, form.get('sugarAlcohol_g').value) : null">
              <validation-errors for="sugarAlcohol_g"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="starch_g" label="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_starch" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label_description.nutrition_starch"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Starch_g, form.get('starch_g').value) : null">
              <validation-errors for="starch_g"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="dietaryFiber_g" label="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_dietary_fiber" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label_description.nutrition_dietary_fiber"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.DietaryFiber_g, form.get('dietaryFiber_g').value) : null">
              <validation-errors for="dietaryFiber_g"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="protein_g" label="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_protein" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label_description.nutrition_protein"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Protein_g, form.get('protein_g').value) : null">
              <validation-errors for="protein_g"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="sodium_g" label="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label.nutrition_sodium" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_nutrition_facts.label_description.nutrition_sodium"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Sodium_g, form.get('sodium_g').value) : null">
              <validation-errors for="sodium_g"></validation-errors>
            </form-field>

          </div>
        </div>

      </div>
    </section-expanding>

    <section-expanding [expanded]="shouldExpandSections">
      <span header-title>{{'cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.title'
        | translate}}</span>
      <div body>

        <div class="row">
          <div class="col-6 no-padding">

            <form-field [readonly]="!edit" for="vitaminA_mcg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_vitamin_a" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_vitamin_k"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.VitaminA_mcg, form.get('vitaminA_mcg').value) : null">
              <validation-errors for="vitaminA_mcg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="vitaminC_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_vitamin_c" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_vitamin_c"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.VitaminC_mg, form.get('vitaminC_mg').value) : null">
              <validation-errors for="vitaminC_mg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="vitaminD_mcg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_vitamin_d" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_vitamin_d"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.VitaminD_mcg, form.get('vitaminD_mcg').value) : null">
              <validation-errors for="vitaminD_mcg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="vitaminE_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_vitamin_e" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_vitamin_e"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.VitaminE_mg, form.get('vitaminE_mg').value) : null">
              <validation-errors for="vitaminE_mg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="vitaminK_mcg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_vitamin_k" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_vitamin_k"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.VitaminK_mcg, form.get('vitaminK_mcg').value) : null">
              <validation-errors for="vitaminK_mcg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="vitaminB6_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_vitamin_b6" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_vitamin_b6"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.VitaminB6_mg, form.get('vitaminB6_mg').value) : null">
              <validation-errors for="vitaminB6_mg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="vitaminB12_mcg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_vitamin_b12" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_vitamin_b12"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.VitaminB12_mcg, form.get('vitaminB12_mcg').value) : null">
              <validation-errors for="vitaminB12_mcg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="biotin_mcg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_biotin" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_biotin"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Biotin_mcg, form.get('biotin_mcg').value) : null">
              <validation-errors for="biotin_mcg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="fluoride_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_fluoride" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_fluoride"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Fluoride_mg, form.get('fluoride_mg').value) : null">
              <validation-errors for="fluoride_mg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="folicAcid_mcg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_folic_acid" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_folic_acid"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.FolicAcid_mcg, form.get('folicAcid_mcg').value) : null">
              <validation-errors for="folicAcid_mcg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="phosphorus_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_phosphorus" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_phosphorus"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Phosphorus_mg, form.get('phosphorus_mg').value) : null">
              <validation-errors for="phosphorus_mg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="iodine_mcg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_iodine" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_iodine"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Iodine_mcg, form.get('iodine_mcg').value) : null">
              <validation-errors for="iodine_mcg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="potassium_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_potassium" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_potassium"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Potassium_mg, form.get('potassium_mg').value) : null">
              <validation-errors for="potassium_mg"></validation-errors>
            </form-field>

          </div>
          <div class="col-6 no-padding">

            <form-field [readonly]="!edit" for="calcium_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_kaltsium" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_kaltsium"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Calcium_mg, form.get('calcium_mg').value) : null">
              <validation-errors for="calcium_mg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="chromium_mcg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_chromium" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_chromium"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Chromium_mcg, form.get('chromium_mcg').value) : null">
              <validation-errors for="chromium_mcg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="magnesium_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_magnesium" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_magnesium"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Magnesium_mg, form.get('magnesium_mg').value) : null">
              <validation-errors for="magnesium_mg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="manganese_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_manganese" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_manganese"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Manganese_mg, form.get('manganese_mg').value) : null">
              <validation-errors for="manganese_mg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="molybdenum_mcg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_molybdenum" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_molybdenum"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Molybdenum_mcg, form.get('molybdenum_mcg').value) : null">
              <validation-errors for="molybdenum_mcg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="niacin_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_niacin" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_niacin"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Niacin_mg, form.get('niacin_mg').value) : null">
              <validation-errors for="niacin_mg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="pantothenicAcid_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_pantothenicacid" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_pantothenicacid"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.PantothenicAcid_mg, form.get('pantothenicAcid_mg').value) : null">
              <validation-errors for="pantothenicAcid_mg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="iron_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_iron" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_iron"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Iron_mg, form.get('iron_mg').value) : null">
              <validation-errors for="iron_mg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="riboflavin_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_riboflavin" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_riboflavin"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Riboflavin_mg, form.get('riboflavin_mg').value) : null">
              <validation-errors for="riboflavin_mg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="selenium_mcg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_selenium" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_selenium"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Selenium_mcg, form.get('selenium_mcg').value) : null">
              <validation-errors for="selenium_mcg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="thiamin_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_thiamin" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_thiamin"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Thiamin_mg, form.get('thiamin_mg').value) : null">
              <validation-errors for="thiamin_mg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="zinc_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_zinc" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_zinc"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Zinc_mg, form.get('zinc_mg').value) : null">
              <validation-errors for="zinc_mg"></validation-errors>
            </form-field>

            <form-field [readonly]="!edit" for="copper_mg" label="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label.nutrition_copper" type="input-text"
              description="cards.products.product_certificate_info_and_nutrition_info.product_vitamins_minerals.label_description.nutrition_copper"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Copper_mg, form.get('copper_mg').value) : null">
              <validation-errors for="copper_mg"></validation-errors>
            </form-field>
          </div>
        </div>

      </div>

    </section-expanding>

    <section-expanding [expanded]="shouldExpandSections">
      <span header-title>{{ 'cards.products.product_certificate_info_and_nutrition_info.product_physico_chemical_indicators.title' | translate }}</span>
      <div body>

        <div class="row">
          <div class="col-6 no-padding">

            <form-field for="nitrite" type="input-text" [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_physico_chemical_indicators.label.nitrite"
              description="cards.products.product_certificate_info_and_nutrition_info.product_physico_chemical_indicators.label_description.nitrite"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.Nitrite, form.get('nitrite').value) : null">
              <validation-errors for="nitrite"></validation-errors>
            </form-field>

          </div>
          <div class="col-6 no-padding">

            <form-field for="phosphorusPentoxide" type="input-text" [readonly]="!edit"
              label="cards.products.product_certificate_info_and_nutrition_info.product_physico_chemical_indicators.label.phosphorus_pentoxide"
              description="cards.products.product_certificate_info_and_nutrition_info.product_physico_chemical_indicators.label_description.phosphorus_pentoxide"
              [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.PhosphorusPentoxide, form.get('phosphorusPentoxide').value) : null">
              <validation-errors for="phosphorusPentoxide"></validation-errors>
            </form-field>

          </div>
        </div>

      </div>
    </section-expanding>

  </div>

  <div footer class="flex flex-row" *ngIf="edit">
    <lxm-button class="ml15" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()"></lxm-button>
    <lxm-button class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()">
    </lxm-button>
  </div>

</card-expanding>