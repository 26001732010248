import { AfterViewInit, Component, Inject, Optional, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogComponent } from "src/app/components";
import { LOCAL_STORAGE_KEY } from "src/app/config";
import { ContentLanguage, LocalStorage } from "src/app/_helpers";

@Component({
  selector: "retailer-details-copy-dialog",
  host: { class: "dialog" },
  templateUrl: "./retailer-details-copy.dialog.html",
  styleUrls: ["./retailer-details-copy.dialog.scss"]
})
export class RetailerDetailsCopyDialog implements AfterViewInit {

  @ViewChild('dialog', { static: true, read: DialogComponent })
  private _dialog: DialogComponent;

  public readonly = false;
  public product: any;

  public form: FormGroup;
  public isLoading = false;

  public suggestedOptions = [];

  constructor(
    public dialogRef: MatDialogRef<RetailerDetailsCopyDialog>,
    private _contentLanguage: ContentLanguage,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.readonly = data.readonly
    this.product = data.product;

    const copySegment = LocalStorage.getValue(LOCAL_STORAGE_KEY.RETAILER_DETAILS_COPY_SEGMENT);
    const copyCodes = LocalStorage.getValue(LOCAL_STORAGE_KEY.RETAILER_DETAILS_COPY_CODES);
    const copyTags = LocalStorage.getValue(LOCAL_STORAGE_KEY.RETAILER_DETAILS_COPY_TAGS);
    const copyParameters = LocalStorage.getValue(LOCAL_STORAGE_KEY.RETAILER_DETAILS_COPY_EXTRA_PARAMETERS);

    this.form = new FormGroup({
      selectedProduct: new FormControl(),
      copySegment: new FormControl(copySegment ?? true),
      copyCodes: new FormControl(copyCodes ?? true),
      copyTags: new FormControl(copyTags ?? true),
      copyExtraParameters: new FormControl(copyParameters ?? true)
    });
  }

  public ngAfterViewInit(): void {

    this._dialog.dataLoaded$
      .subscribe(state => {
        if (!state) {
          return;
        }

        const { suggestedProducts } = this.data.formData;
        this.suggestedOptions = suggestedProducts;
      });
  }

  public get hasMatches() {
    return this.suggestedOptions?.length > 0;
  }

  public selectProduct(product: any) {
    const selectedProduct = this.form.get('selectedProduct').value;

    if (selectedProduct?.id === product.id) {
      this.form.patchValue({
        selectedProduct: null
      });
    } else {
      this.form.patchValue({
        selectedProduct: product
      });
    }
  }

  public productAcLabel = (item: any) => {
    let label = this._contentLanguage.get(item.name);

    if (item.supplier?.name) {
      label += ` [${item.supplier.name}]`;
    }

    if (item.ean) {
      label += ` (${item.ean})`;
    }

    if (item.internalCode) {
      label += ` (${item.internalCode})`;
    }

    return label;
  }

  public saveLocalValue(value: any, formKey: string) {
    switch (formKey) {
      case 'copySegment':
        LocalStorage.set(LOCAL_STORAGE_KEY.RETAILER_DETAILS_COPY_SEGMENT, value.checked);
        break;
      case 'copyCodes':
        LocalStorage.set(LOCAL_STORAGE_KEY.RETAILER_DETAILS_COPY_CODES, value.checked);
        break;
      case 'copyTags':
        LocalStorage.set(LOCAL_STORAGE_KEY.RETAILER_DETAILS_COPY_TAGS, value.checked);
        break;
      case 'copyExtraParameters':
        LocalStorage.set(LOCAL_STORAGE_KEY.RETAILER_DETAILS_COPY_EXTRA_PARAMETERS, value.checked);
        break;
    }
  }

  public productAcExcludeIds = () => {
    return [this.product.id];
  }

  public closeDialog(data?: any): void {
    this.dialogRef.close(data);
  }

  public copy() {
    this.isLoading = true;
    const f = this.form.value;

    const res = {
      copySegment: f.copySegment,
      copyCodes: f.copyCodes,
      copyTags: f.copyTags,
      copyExtraParameters: f.copyExtraParameters,
      segment: f.selectedProduct?.segment,
      codes: f.selectedProduct?.codes,
      tags: f.selectedProduct?.tags,
      extraParameters: {
        parameter1: f.selectedProduct?.parameter1,
        parameter2: f.selectedProduct?.parameter2,
        parameter3: f.selectedProduct?.parameter3,
        parameter4: f.selectedProduct?.parameter4,
        parameter5: f.selectedProduct?.parameter5,
        parameter6: f.selectedProduct?.parameter6,
        parameter7: f.selectedProduct?.parameter7,
        parameter8: f.selectedProduct?.parameter8,
        parameter9: f.selectedProduct?.parameter9,
        parameter10: f.selectedProduct?.parameter10
      }
    };

    this.closeDialog(res);
  }

}