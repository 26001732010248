import {
  ApplicationRef,
  ChangeDetectorRef,
  Injectable,
  NgZone
} from "@angular/core";
import { Subject } from "rxjs";
import { AppState } from "../state/app.state";

export const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xl2: 1536
};

@Injectable({
  providedIn: "root"
})
export class ResponsiveHelper {
  constructor(
    private _zone: NgZone,
    private _state: AppState
  ) {}

  public getLayout(): void {

    this._zone.run(() => {
      const win = window,
        doc = document,
        docElem = doc.documentElement,
        body = doc.getElementsByTagName("body")[0],
        w = win.innerWidth || docElem.clientWidth || body.clientWidth;

      this._state.clientWidth = w;
      this._state.updateApplicationSubject$.next();
    });
  }
}
