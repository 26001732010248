import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IDisplayProduct } from "src/app/models/IDisplayProduct";
import { environment } from "src/environments/environment";
@Component({
  selector: "display-product",
  templateUrl: "./display-product.component.html",
  styleUrls: ["./display-product.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayProductComponent {
  
  @Input() public data: IDisplayProduct;

  @Input() public productId: string;
  @Input() public isTranslated = false;
  @Input() public productName: string | any;
  @Input() public productEan: string;
  @Input() public imageUrl: string;
  @Input() public thumbnail: string;
  @Input() public thumbnailBase64 = true;
  @Input() public thumbnailUrl: string;
  @Input() public thumbnailContentType: string;
  @Input() public status: number;


  private _createImageUrl(id) {
    if (!id) {
      return null;
    }
    return `${environment.apiUrl}api/products/${id}/images`;
  }

  public get pName() {
    return this.productName ?? this.data?.name;
  }

  public get pEan() {
    return this.productEan ?? this.data?.ean;
  }

  public get pId() {
    return this.productId ?? this.data?.id;
  }

  public get pLink() {
    return this.pId ? `/products/${this.pId}` : null;
  }

  public get pImageUrl() {
    return this.imageUrl ?? this.data?.imageUrl ?? this._createImageUrl(this.pId);
  }

  public get pThumbnail() {
    return this.thumbnail ?? this.data?.thumbnail;
  }

  public get pThumbnailUrl() {
    return this.thumbnailUrl ?? this.data?.thumbnailUrl;
  }

  public get pThumbnailContentType() {
    return this.thumbnailContentType ?? this.data?.thumbnailContentType;
  }

  public get pStatus() {
    return this.status ?? this.data?.assortmentStatus;
  }

}
