<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form" #dialog>

  <span header-title>
    {{ 'cards.offer.retailer_details.copy_dialog.title' | translate }}
  </span>

  <scope body class="dialog-column-wrapper" translationsPath="cards.offer.retailer_details.copy_dialog">

    <display-product class="dialog-display-product-sticky mt10 mb10" [data]="product" [isTranslated]="true" [productId]="product.id"></display-product>

    <info-array prelude="cards.offer.retailer_details.copy_dialog.info_text"></info-array>

    <div class="separator !my-5"></div>

    <div [ngSwitch]="hasMatches" class="matches-container" [class.has-matches]="hasMatches">

      <div *ngSwitchCase="true">
        <div class="bold mb15">
          {{ 'cards.offer.retailer_details.copy_dialog.label.product_matches' | translate }}
        </div>
        <div class="matches-list">
          <div
            *ngFor="let suggestedProduct of suggestedOptions"
            class="product-match mb10"
            [class.selected]="form.value.selectedProduct?.id == suggestedProduct.id"
            (click)="selectProduct(suggestedProduct)"
          >
            {{ suggestedProduct.name | translatedValue }}&nbsp;
            <span *ngIf="suggestedProduct.supplier?.name">
              [{{ suggestedProduct.supplier?.name }}]
            </span>
            <span *ngIf="suggestedProduct.ean">
              ({{ suggestedProduct.ean }})
            </span>
            <span *ngIf="suggestedProduct.internal">
              ({{ suggestedProduct.internal }})
            </span>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="false">
        {{ 'cards.offer.retailer_details.copy_dialog.label.product_matches_not_found' | translate }}
      </div>

    </div>

    <div class="separator"></div>

    <div class="flex flex-col ml10">
      <span class="bold mb10">{{ 'cards.offer.retailer_details.copy_dialog.label.products_search' | translate }}</span>
      <form-field dialog [noLabel]="true">
        <lxm-select
          select
          for="selectedProduct"
          [searchable]="true"
          [labelFn]="productAcLabel"
          [sortByLabel]="true"
          url="api/products/retailerDetails/ac/"
          [acData]="productAcExcludeIds"
        ></lxm-select>
        <validation-errors for="selectedProduct" aliasTo="selectedProductId"></validation-errors>
      </form-field>
    </div>

    <div class="separator"></div>

    <div class="row">
      <div class="no-padding">
        <form-field [readonly]="readonly" label="segment" description="segment">
          <slide-toggle
            toggle
            for="copySegment"
            [readonly]="readonly"
            (change)="saveLocalValue($event, 'copySegment')"
          ></slide-toggle>
          <validation-errors for="copySegment"></validation-errors>
        </form-field>

        <form-field [readonly]="readonly" label="product_codes" description="product_codes">
          <slide-toggle
            toggle
            for="copyCodes"
            [readonly]="readonly"
            (change)="saveLocalValue($event, 'copyCodes')"
          ></slide-toggle>
          <validation-errors for="copyCodes"></validation-errors>
        </form-field>

        <form-field [readonly]="readonly" label="tags" description="tags">
          <slide-toggle
            toggle
            for="copyTags"
            [readonly]="readonly"
            (change)="saveLocalValue($event, 'copyTags')"
          ></slide-toggle>
          <validation-errors for="copyTags"></validation-errors>
        </form-field>

        <form-field [readonly]="readonly" label="extra_parameters" description="extra_parameters">
          <slide-toggle
            toggle
            for="copyExtraParameters"
            [readonly]="readonly"
            (change)="saveLocalValue($event, 'copyExtraParameters')"
          ></slide-toggle>
          <validation-errors for="copyExtraParameters"></validation-errors>
        </form-field>
      </div>
    </div>

    <div class="separator"></div>

  </scope>

  <div footer class="justify-between">
    <div class="flex flex-row">
      <lxm-button
        *ngIf="!readonly"
        type="primary"
        label="cards.offer.retailer_details.copy_dialog.label.copy"
        (click)="copy()"
        [loading]="isLoading"
      ></lxm-button>
      <lxm-button
        type="secondary"
        [class.ml10]="!readonly"
        label="action.cancel"
        (click)="closeDialog(null)"
      ></lxm-button>
    </div>
  </div>

</lxm-dialog>