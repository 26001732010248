<div class="flex w-full items-center justify-end py-5">
  <guest-language-selector></guest-language-selector>
</div>

<div class="login-content">
  <div class="login-container">
    <!-- <div class="reset-message-container">
      <card-message
        *ngIf="errors.length > 0"
        [messages]="errors"
        [absolute]="true"
      ></card-message>
    </div> -->

    <div class="login-card">
      <div class="login-card-left">
        <img
          class="login-card-img img--"
          src="/assets/img/login/group-13.png"
          draggable="false"
        />
      </div>

      <div class="login-card-right" [ngSwitch]="view">
        <ng-container *ngSwitchCase="passwordView">
          <div class="reset-password-info">
            <span class="reset-password-title">{{
              "login.new_password.title" | translate
            }}</span>
          </div>
          <form
            class="login-tab-container mt30"
            [formGroup]="resetForm"
            (ngSubmit)="requestNewPassword()"
            novalidate
          >
            <lxm-input
              class="login-input mb12"
              type="password"
              for="password"
              autocomplete="off"
              placeholder="{{
                'login.new_password.placeholders.new_password' | translate
              }}"
            >
              <validation-errors for="password"></validation-errors>
            </lxm-input>

            <lxm-input
              class="login-input"
              type="password"
              for="passwordConfirm"
              autocomplete="off"
              placeholder="{{
                'login.new_password.placeholders.confirm_new_password'
                  | translate
              }}"
            >
              <validation-errors for="passwordConfirm"></validation-errors>
            </lxm-input>

            <lxm-button
              class="mt30 max-w-full"
              width="280px"
              type="primary"
              buttonType="submit"
              label="login.new_password.confirm"
              [loading]="newPasswordLoading"
            ></lxm-button>
          </form>
        </ng-container>
        <ng-container *ngSwitchCase="successView">
          <div class="reset-password-info">
            <span class="reset-password-title">{{
              "login.new_password.title_success" | translate
            }}</span>
            <span class="reset-password-text mt12">
              {{ "login.new_password.text_success" | translate }}
            </span>
            <svg-icon
              key="sentCheckmarkCircle"
              fontSize="64px"
              class="mt30"
            ></svg-icon>
          </div>

          <a class="forgot-password-link mt30" routerLink="/login">{{
            "login.new_password.back_to_log_in" | translate
          }}</a>
        </ng-container>
      </div>
    </div>

    <div class="login-footer mb20">
      <copyright-block></copyright-block>
    </div>
  </div>
</div>
