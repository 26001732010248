
<search [formGroup]="searchData" [isOpen]="isOpen" [isLoading]="state.isSearchLoading" (onToggle)="toggleOpen($event)" placeholder="cards.offers.offers_supplier_search.placeholder" (onSubmit)="submit()">
  <scope panel-left translationsPath="cards.offers.offers_supplier_search">
    <form-field panel label="offer_date">
      <div any class="flex flex-row date-range">
        <lxm-datepicker for="dateFrom" class="f-1"></lxm-datepicker>
        <div class="date-range__separator">-</div>
        <lxm-datepicker for="dateTo" class="f-1"></lxm-datepicker>
      </div>
    </form-field>
    <form-field panel label="offer_type">
      <lxm-select select for="offerType" labelField="name" [options]="offerTypeOptions" value="id">
      </lxm-select>
    </form-field>
    <form-field panel label="offer_status">
      <lxm-select select labelField="name" [searchable]="true" for="offerStatus" [multiple]="true" [options]="statusOptions" value="id">
      </lxm-select>
    </form-field>
  </scope>
  <scope panel-right translationsPath="cards.offers.offers_supplier_search">
    <form-field panel label="retailer">
      <lxm-select select labelField="name" [searchable]="true" for="retailers" [multiple]="true" [options]="retailerOptions" [sortByLabel]="true" value="id">
      </lxm-select>
    </form-field>
    <form-field panel label="purchase_manager">
      <lxm-select select labelField="displayName" [searchable]="true" for="recipients" [multiple]="true" [options]="recipientsOptions" groupBy="tenantName" value="userId">
      </lxm-select>
    </form-field>
    <form-field panel label="owner">
      <lxm-select select labelField="name" [searchable]="true" for="offerOwner" [multiple]="true" [options]="userOptions" value="id">
      </lxm-select>
    </form-field>
    <form-field panel label="show_inactive_users">
      <slide-toggle toggle for="showInactiveUsers"></slide-toggle>
    </form-field>
  </scope>
</search>