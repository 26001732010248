<div class="image-preview-container" [popper]="tooltipcontent" [popperTrigger]="popperTrigger" [popperDelay]="delay"
  [popperPlacement]="position" [popperPositionFixed]="popperPositionFixed"
  [popperHideOnMouseLeave]="popperHideOnMouseLeave" [popperShowOnStart]="popperShowOnStart" [popperAppendTo]="appendTo"
  (popperOnShown)="onShown()" [class.no-status]="!hasStatus">
  <span class="image-preview-status-icon" [class.active]="isActive" [class.inactive]="isInactive"
    [class.pending]="isPending" [class.active-bottom]="isActiveBottom" [style.background-color]="statusColor">
  </span>
  <span class="image-preview-image-container">
    <img class="image-preview-image {{imageFit}}" [class.fallback]="localThumbnail" [src]="thumbnailSource" />
  </span>
</div>

<popper-content #tooltipcontent class="image-popover" (click)="preventPropagation()">
  <div class="image-popover-content">
    <div class="image-popover-image-container">
      <img class="image-popover-image" [src]="imageSource" [class.fallback]="localImage" *ngIf="tooltipContentVisible" />
    </div>
    <div class="image-popover-footer" *ngIf="hasStatus || isImageIllustrative">

      <ng-container *ngIf="hasStatus">
        <span class="image-preview-status-icon" style="height: 40px;" *ngIf="description"
        [class.active]="isActive" [class.inactive]="isInactive" [class.pending]="isPending"
        [class.active-bottom]="isActiveBottom">
      </span>
      <div class="image-popover-status-container ml10">
        <div class="flex flex-row align-center">
          <span class="image-popover-status-icon" *ngIf="!description" [class.active]="isActive"
            [class.inactive]="isInactive" [class.pending]="isPending" [style.background-color]="statusColor">
          </span>
          <!-- <status-circle [status]="status" [enum]="type === 'product' ? 'SupplierProductAssortmentStatus' : 'Status'"></status-circle> -->
          <span class="image-popover-status-text">
            <span>{{statusText ? statusText : (status | translateEnum: StatusEnum)}}</span>
          </span>
        </div>
        <div *ngIf="description" class="flex align-center ml5">
          <span style="font-size: 12px;">
            {{description}}
          </span>
        </div>
      </div>
      </ng-container>

      <div class="image-illustrative-text" *ngIf="isImageIllustrative">
          {{'global.image_is_illustrative' | translate}}
      </div>

    </div>
  </div>
</popper-content>