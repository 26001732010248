import { ApplicationRef, Component } from "@angular/core";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { Subscription } from "rxjs";
import { AuthenticationService, SignalRService } from "./_services";
import { TitleService } from "./_services/title.service";
import { AppState } from "./state/app.state";
import { ResponsiveHelper } from "./_helpers/responsive";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "Lexi Market";

  private _hubConnection: HubConnection;
  private _signalRSubscription: Subscription;
  private _lastTenantId: string;

  public constructor(
    private _state: AppState,
    private _ar: ApplicationRef,
    private _titleService: TitleService,
    private _signalRService: SignalRService,
    private _authService: AuthenticationService,
    private _responsive: ResponsiveHelper
  ) {
    this._titleService.setTitle(this.title);
  }

  public ngOnInit() {
    this._authService.jwt.subscribe((x) => {
      if (!x) {
        this._leaveTenant(this._lastTenantId);
        return;
      }

      this._joinTenant(x.CurrentTenantId);
    });

    // this._state.updateApplicationSubject$
    //   .pipe(debounceTime(50))
    //   .subscribe((val) => {
    //     this._responsive.getLayout();
    //     this._ar.tick();
    //   });

    this._responsive.getLayout();
    window.onresize = () => {
      this._responsive.getLayout();
    };
  }

  public ngOnDestroy() {
    this._leaveTenant(this._lastTenantId);
  }

  private _joinTenant(tenantId: string) {
    this._lastTenantId = tenantId;
    this._signalRSubscription = this._signalRService.commonHub.subscribe(
      (connection) => {
        this._hubConnection = connection;
        if (this._signalRService.isHubConnected(connection)) {
          connection.send("joinTenant");
        }
      }
    );
  }

  private _leaveTenant(tenantId: string) {
    if (!tenantId) {
      return;
    }

    if (this._signalRService.isHubConnected(this._hubConnection)) {
      this._hubConnection.send("leaveTenant", tenantId);
    }

    if (this._signalRSubscription) {
      this._signalRSubscription.unsubscribe();
    }
  }
}
