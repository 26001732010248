import { Component } from '@angular/core';
import { AppState } from 'src/app/state/app.state';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { asArray, ReceivingOfferStatus, Status } from 'src/app/enum';
import { OffersSearchDataService } from 'src/app/_services';
import { OffersListState } from '../offers-list.state';
import { OffersSearchCardBase } from './offers-search.card';

@Component({
  selector: "buyer-offers-search-card",
  templateUrl: './buyer-offers-search.card.html'
})
export class BuyerOffersSearchCardComponent extends OffersSearchCardBase {

  public constructor(
    appState: AppState,
    state: OffersListState,
    public offersSearchDataService: OffersSearchDataService,
    route: ActivatedRoute
  ) {
    super(appState, state, offersSearchDataService, route);

    this.offerTypeOptions = this.formData?.offerTypes || [];

    this.statusOptions = asArray(ReceivingOfferStatus) || [];
    this._updateRetailerRecipientOptions(this.formData?.users, this.searchData.get('showInactiveRecipients').value);
    this.searchData?.get('showInactiveRecipients')?.valueChanges
    .subscribe((showInactiveRecipients: boolean) => {
      let users = this.formData?.users;
      this._updateRetailerRecipientOptions(users, showInactiveRecipients);
    });
  }

  public get searchData() {
    return this.offersSearchDataService.buyerSearchData;
  }
  
  private _getRetailerRecipientOptions(users: any []): any[] {
    if (!users) return [];
    return users.map(u => ({...u, displayName: u.name || u.email}));
  }

  private _updateRetailerRecipientOptions(users: any[], showInactive: boolean): void {
    this.recipientsOptions = showInactive ? 
      this._getRetailerRecipientOptions(users) :
      this._getRetailerRecipientOptions(users?.filter(x => x.status === Status.Active));
  }

  override _serializeSearchData() {
    return this.offersSearchDataService.serializeBuyerSearchData();
  }

}