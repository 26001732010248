<svg-icon
  class="field-description-icon"
  [key]="readonly ? 'qmarkBlack' : 'qmarkGrey'"
  fontSize="14px"
  [popper]="descriptionContent"
  popperTrigger="hover"
  [popperPlacement]="position"
  [popperPositionFixed]="true"
  popperAppendTo="app-root"
>
</svg-icon>
<popper-content #descriptionContent>
  <div
    class="description-content"
    [innerHTML]="description"
  ></div>
</popper-content>
