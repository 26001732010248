import { ChangeDetectorRef, Component, Inject, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { DialogComponent } from 'src/app/components';
import { AuthenticationService, OfferService } from 'src/app/_services';
import { LxmAppModule, StatusRule, UserAction } from 'src/app/enum';
import { LocalStorage, LxmDialog, LxmMessage } from 'src/app/_helpers';
import moment from 'moment';
import { ICurrency } from 'src/app/models';
import { LOCAL_STORAGE_KEY } from 'src/app/config';
import { ProductAssortmentCopyDialog } from './copy-dialog/assortment-copy.dialog';
import { IDisplayProduct } from 'src/app/models/IDisplayProduct';

@Component({
  selector: "offer-product-assortment",
  templateUrl: "./offer-product-assortment.component.html",
  styleUrls: ['./offer-product-assortment.component.scss']
})
export class OfferProductAssortmentComponent {

  @Input() public formGroup: FormGroup;
  @Input() public readonly = false;
  @Input() public data: any;
  @Input() public assortmentData: any;
  @Input() public formData: any;
  @Input() public displayProduct: IDisplayProduct;

  public isDecisionSelectAvailable = false;
  public isDecisionSelectVisible = false;
  public isSaveLoading = false;
  public isDeleteLoading = false;
  public showAssortmentStatusReminder = true;

  public tagsByParentTag = [];

  public form: FormGroup;

  constructor(
    authService: AuthenticationService,
    private _offerService: OfferService,
    private _lxmDialog: LxmDialog,
    private _message: LxmMessage,
    private _cd: ChangeDetectorRef
  ) {
    this.readonly = this.data?.readonly || !authService.hasRight([UserAction.ManageReceivedOffers]);
    this.isDecisionSelectAvailable = authService.hasModule(LxmAppModule.PurchaseManagerDesktop) && authService.hasRight([UserAction.ManageReceivedOffers]);
  }

  public toggleDecisionSelectVisibility(toSet = !this.isDecisionSelectVisible) {
    this.isDecisionSelectVisible = toSet;
  }

  public resetToAppropriateDate() {
    if (this.isDecisionSelected) {
      this.resetToDecisionDate();
    }
    this.resetToPriceSchemeDate();
  }

  public get isValidFromInThePast() {
    const validFrom = moment(this.formGroup.value.assortmentSchemeValidFrom);
    const today = moment().startOf('day');
    return today.isAfter(validFrom);
  }

  public get isResetValidFromVisible() {
    return !this.readonly && (this.isDecisionSelected ? !this.decisionHasSameDate : this.isValidFromInThePast);
  }

  public resetToPriceSchemeDate() {
    const { priceSchemeValidFrom } = this.formGroup.value || {};
    this.formGroup.get('assortmentSchemeValidFrom').setValue(priceSchemeValidFrom, { emitEvent: false });
  }

  public resetToDecisionDate() {
    const decision = this.selectedDecision;
    if (decision) {
      this.onDecisionSelect(decision);
    }
  }

  public getTagsBySelectedParentTag() {
    const { parentTagId } = this.formGroup.value;
    this.tagsByParentTag = this.tags?.[parentTagId] ?? [];
  }

  public get selectedDecision() {
    const decisionId = this.formGroup.value.offerProductDecisionId;
    return this.offerProductDecisionOptions.find(x => x.id === decisionId);
  }

  public get isDecisionSelected() {
    return this.formGroup.get('offerProductDecisionId').value?.length ? true : false;
  }

  public get hasAssortmentId() {
    return this.assortmentId ? true : false;
  }

  public get productId() {
    return this.data?.product?.productId;
  }

  public get offerProductId() {
    return this.data?.product?.id;
  }

  public get offerId() {
    return this.data?.offerId;
  }

  public get isStatusSelected(): boolean {
    return this.formGroup.get('status').value ? true : false;
  }

  public get decisionHasSameDate() {
    const selectedDate = this.formGroup.get('assortmentSchemeValidFrom').value;
    if (selectedDate && !this.isDecisionSelected) {
      return true;
    }
    if (this.selectedDecision) {
      const { validFrom } = this.selectedDecision || {};
      const { editableDate } = validFrom || {}
      return moment(editableDate).isSame(selectedDate);
    }
    return true;
  }

  public onDecisionSelect(decision) {
    if (!decision) {
      this.resetToPriceSchemeDate();
      return;
    }
    const { validFrom } = decision || {};
    const { editableDate } = validFrom || {};
    this.formGroup.get('assortmentSchemeValidFrom').setValue(editableDate ?? validFrom, { emitEvent: false });
  }

  public get assortmentStatusOptions() {
    return this.formData?.assortmentStatuses ?? [];
  }

  public get assortmentGroups() {
    return this.formData?.assortmentGroups ?? {};
  }

  public get retailerSuppliers() {
    return this.formData?.retailerSuppliers ?? [];
  }

  public get parentTags() {
    return this.formData?.parentTags ?? [];
  }

  public get tags() {
    return this.formData?.tags ?? {};
  }

  public get assortmentGroupTags() {
    return this.formData?.assortmentGroupTags ?? {};
  }

  public get assortmentGroupsByTags() {
    return this.formData?.assortmentGroupsByTags ?? {};
  }

  public get offerProductDecisionOptions() {
    return this.formData?.offerProductDecisions ?? [];
  }

  public get assortmentStatuses() {
    return this.formData?.assortmentStatuses ?? [];
  }

  public get assortmentId() {
    return this.assortmentData?.id;
  }

  public get showDateTo() {
    return this.formData?.assortmentClassValidity ? true : false;
  }

  public get offerRetailerSupplierId() {
    return this.formGroup.value?.supplier?.id;
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.assortmentData || changes.formData) {
    
      const { assortmentStatuses, assortmentClassValidity, procurementDateFrom, procurementDateTo } = this.formData || {};

      if(assortmentClassValidity){
        const { editableDate: procurementDateFromEditable, displayDate: procurementDateFromDisplay } = procurementDateFrom || {};
        const { editableDate: procurementDateToEditable, displayDate: procurementDateToDisplay } = procurementDateTo || {};
        var procurementFrom = procurementDateFromEditable || procurementDateFromDisplay;
        var procurementTo = (procurementDateToEditable || procurementDateToDisplay) ?? "";
      }
  
      if (this.assortmentData) {
        const { offerProductDecision, assortmentGroups, status, parentTag, validFrom, validTo,
          id, productPayment, productPaymentSupplierId, isLocalAssortment } = this.assortmentData || {};
        const { editableDate, displayDate } = validFrom || {};
  
        if (assortmentClassValidity) {
          const { editableDate: toEditableDate, displayDate: toDisplayDate } = validTo || {};
          var dateToValue = assortmentClassValidity ? (toEditableDate || toDisplayDate) ?? "" : "";
        }
  
        this.formGroup.patchValue({
          assortmentSchemeValidFrom: editableDate ?? displayDate ?? offerProductDecision?.validFrom?.editableDate ?? null,
          assortmentSchemeValidTo: dateToValue ?? null,
          status: status?.id ?? assortmentStatuses?.find(x => x.statusRule == StatusRule.ActiveByDefault)?.id,
          assortmentGroups: assortmentGroups?.map(x => x.id) || [],
          offerProductDecisionId: offerProductDecision?.id,
          parentTagId: parentTag?.id || LocalStorage.getValue(LOCAL_STORAGE_KEY.ASSORTMENT_PARENT_TAG_ID) || this.parentTags?.[0]?.id || null,
          payment: productPayment,
          productPaymentSupplier: productPaymentSupplierId ?? this.offerRetailerSupplierId
        }, { emitEvent: this.readonly });

        if (!isLocalAssortment) {
          // local (rPIM) assortment is for prefilling the dialog with exist assortment detail only
          // on save, new assortment should be created
          this.assortmentData.id = id;
        }
      } else {
        this.formGroup.patchValue({
          assortmentSchemeValidFrom: procurementFrom || moment().startOf('day').add(1, 'day'),
          assortmentSchemeValidTo: procurementTo,
          parentTagId: LocalStorage.getValue(LOCAL_STORAGE_KEY.ASSORTMENT_PARENT_TAG_ID) || this.parentTags?.[0]?.id || null,
          status: assortmentStatuses?.find(x => x.statusRule == StatusRule.ActiveByDefault)?.id,
          productPaymentSupplier: this.offerRetailerSupplierId
        }, { emitEvent: false });
      }

      this.getTagsBySelectedParentTag();
    }
  }

  public setParentTagIdLocalStorageValue(parentTag) {
    const parentTagId = parentTag?.id;
    if (parentTagId) {
      LocalStorage.set(LOCAL_STORAGE_KEY.ASSORTMENT_PARENT_TAG_ID, parentTagId);
    }
  }

  public onParentTagChange(parentTag) {
    this.setParentTagIdLocalStorageValue(parentTag);
    this.getTagsBySelectedParentTag();
  }

  ngAfterViewInit(): void {
    this.formGroup.get('status').valueChanges
      .subscribe(val => {
        if (this.assortmentStatusOptions?.find(x => x.id == val && x.statusRule == StatusRule.InactiveByDefault)) {
          this.formGroup.patchValue({
            assortmentGroups: []
          });
        }
      });
  }

  public deleteAssortment() {
    this._lxmDialog.confirm({
      title: 'cards.offer.assortment_dialog.confirm_delete.title',
      message: 'cards.offer.assortment_dialog.confirm_delete.text'
    }, () => {
      this.isDeleteLoading = true;

      // this._offerService.deleteOfferProductRetailerAssortmentScheme(this.offerId, this.offerProductId, this.assortmentId)
      //   .result(null, res => {
      //     this.isDeleteLoading = false;
      //   }, e => {
      //     this.isDeleteLoading = false;
      //   });
    });
  }

  public openCopyDialog() {
    this._lxmDialog.open(ProductAssortmentCopyDialog, {
      width: '1200px',
      height: '750px',
      data: {
        readonly: this.readonly,
        product: this.displayProduct,
        segmentId: this.data.product?.retailerDetails?.segment?.id
      }
    }, res => {
      if (!res) {
        return;
      }

      const { assortmentGroupTagId, assortmentGroups, decisionId, id, isCurrent, isEditable, modifiedBy, modifiedById, offerId, status, validFrom, validTo } = res || {};

      let patch = {
        assortmentGroups: assortmentGroups?.map(x => x.id) ?? [],
      };

      this.formGroup.patchValue(patch);
    });
  }

}


interface IOfferProductAssortmentGroupsFormGroup {
  dateFrom: Date;
  status: any;
  assortmentGroups: any[];
  offerProductDecisionId: string;
  parentTagId: string;
  payment: any;
  productPaymentSupplier: any;
}

