<lxm-dialog
  [dialogRef]="dialogRef"
  [formGroup]="form"
  [disableContentMargin]="true"
  #dialog
>
  <div header-title class="flex flex-col gap-1">
    <span class="dialog-label">
      {{'cards.purchase_manager_desktop.products_list.table.retailer_details'
      | translate}}
    </span>
    <span class="product-name">
      {{productName | translatedValue:false:''}}
    </span>
  </div>

  <scope
    body
    translationsPath="cards.offer.retailer_details"
    class="dialog-column-wrapper"
  >
    <div class="dialog-column-left dialog-column-sticky col-4 col-md-4">
      <div class="row mb10">
        <div class="col-12 col-md-12">
          <div class="f-0 flex" style="position: absolute; top: 25px">
            <image-box
              class="main-image"
              [images]="[productImage]"
              [index]="0"
              imageFit="contain"
              size="3"
            >
            </image-box>
          </div>
          <form-field class="mb0">
            <div class="nowrap input-padding align-center flex flex-row" any>
              <status-circle
                class="mr5"
                [status]="product?.assortmentStatus"
                enum="SupplierProductAssortmentStatus"
              ></status-circle>
              <span class="product-header-text">
                {{product?.assortmentStatus | translateEnum:
                'SupplierProductAssortmentStatus'}}
              </span>
            </div>
          </form-field>
          <form-field class="mb0">
            <div any class="input-padding flex flex-col">
              <span class="product-header-label"
                >{{'table.barcode' | translate}}</span
              >
              <span class="product-header-text">{{ productEan || '-' }}</span>
            </div>
          </form-field>
          <form-field class="mb0">
            <div any class="input-padding flex flex-col">
              <span class="product-header-label"
                >{{'enums.ProductUnitCodeType.SupplierCode' | translate}}</span
              >
              <span class="product-header-text">{{ productSupplierCode || '-' }}</span>
            </div>
          </form-field>

          <form-field
            [readonly]="readonly"
            label="brand"
            for="brand"
            description="brand"
          >
            <lxm-select
              select
              [readonly]="readonly"
              labelField="value"
              [searchable]="true"
              for="brand"
              [options]="productBrands"
            >
            </lxm-select>
            <validation-errors for="brand"></validation-errors>
          </form-field>

          <form-field
            [readonly]="readonly"
            label="subbrand"
            for="subBrand"
            description="subbrand"
          >
            <lxm-select
              select
              [readonly]="readonly"
              labelField="value"
              [searchable]="true"
              for="subBrand"
              [options]="productSubBrands"
            >
            </lxm-select>
            <validation-errors for="subBrand"></validation-errors>
          </form-field>

          <form-field
            [readonly]="readonly"
            label="model"
            for="model"
            type="input-text"
            description="model"
          >
            <validation-errors for="model"></validation-errors>
          </form-field>

          <form-field
            [readonly]="readonly"
            label="origin"
            for="countryOfOrigin"
            description="origin"
          >
            <lxm-select
              select
              [readonly]="readonly"
              [options]="countries"
              labelField="iso3"
              [sortByLabel]="true"
              [searchable]="true"
              for="countryOfOrigin"
              labelIconField="iso3"
              labelIconFallback="global"
              labelIconClass="flag-icon"
            >
            </lxm-select>
            <!-- labelField="translatedName" -->
            <!-- [isTranslated]="true" -->
            <validation-errors for="countryOfOrigin"></validation-errors>
          </form-field>

          <!-- only required for unit type kg -->
          <form-field
            *ngIf="markingPriceRequiredVisible"
            [readonly]="readonly"
            label="marking_price_required"
            description="marking_price_required"
          >
            <slide-toggle
              toggle
              for="markingPriceRequired"
              [readonly]="readonly"
            ></slide-toggle>
            <validation-errors for="markingPriceRequired"></validation-errors>
          </form-field>

          <div class="separator"></div>

          <form-field
            [readonly]="readonly"
            label="order_unit"
            for="orderUnit"
            description="order_unit"
          >
            <lxm-select
              select
              [readonly]="readonly"
              [options]="units"
              labelField="value"
              for="orderUnit"
            >
            </lxm-select>
            <validation-errors for="orderUnit"></validation-errors>
          </form-field>

          <form-field
            [readonly]="readonly"
            label="sales_unit"
            for="salesUnit"
            description="sales_unit"
          >
            <lxm-select
              select
              [readonly]="readonly"
              [options]="units"
              labelField="value"
              for="salesUnit"
            >
            </lxm-select>
            <validation-errors for="salesUnit"></validation-errors>
          </form-field>

          <form-field
            *ngIf="isAverageWeightVisible"
            [readonly]="readonly"
            label="average_weight"
            for="averageWeightInGrams"
            type="input-text"
            description="average_weight"
          >
            <validation-errors for="averageWeightInGrams"></validation-errors>
          </form-field>

          <form-field
            [readonly]="readonly"
            label="net_content"
            for="netContent"
            type="input-text"
            description="net_content"
          >
            <validation-errors for="netContent"></validation-errors>
          </form-field>

          <form-field
            [readonly]="readonly"
            label="content_unit"
            for="contentUnit"
            description="content_unit"
          >
            <lxm-select
              select
              [readonly]="readonly"
              [options]="units"
              labelField="value"
              for="contentUnit"
            >
            </lxm-select>
            <validation-errors for="contentUnit"></validation-errors>
          </form-field>

          <form-field
            *ngIf="isDrainedWeightVisible"
            [readonly]="readonly"
            label="drained_weight"
            for="drainedWeightInGrams"
            type="input-text"
            description="drained_weight"
          >
            <validation-errors for="drainedWeightInGrams"></validation-errors>
          </form-field>
        </div>
      </div>
    </div>

    <div class="dialog-column-right col-8 col-md-8">
      <form-field
        [readonly]="readonly"
        labelTop="true"
        label="full_name"
        description="full_name"
      >
        <language-text
          language-text
          for="fullName"
          [readonly]="readonly"
          newline="true"
          maxlength="200"
        >
        </language-text>
      </form-field>

      <form-field
        [readonly]="readonly"
        labelTop="true"
        label="short_name"
        description="short_name"
        class="mb0"
      >
        <language-text
          language-text
          for="shortName"
          [readonly]="readonly"
          newline="true"
          maxlength="40"
        >
        </language-text>
      </form-field>

      <div class="separator"></div>

      <form-field
        [readonly]="readonly"
        label="segment"
        description="segment"
        [class.mb0]="!showOtherSegments"
      >
        <lxm-select
          select
          for="segment"
          labelField="title"
          [options]="segmentOptions"
          [readonly]="readonly"
          [searchable]="true"
        >
        </lxm-select>
        <validation-errors
          for="segment"
          aliasTo="segmentId"
        ></validation-errors>
      </form-field>

      <form-field [readonly]="readonly" *ngIf="showOtherSegments" class="mb0">
        <lxm-select
          select
          for="segmentOther"
          [labelFn]="segmentTreeLabel"
          [options]="segmentOtherOptions"
          [readonly]="readonly"
          [searchable]="true"
          [disableOption]="disableSegmentTreeOption"
        >
        </lxm-select>
      </form-field>

      <div class="separator"></div>

      <form-field
        labelTop="true"
        label="codes"
        description="codes"
        [readonly]="readonly"
        class="mb0"
      >
        <product-code-manager
          any
          for="codes"
          [readonly]="readonly"
          small="true"
          [codeTypes]="codeTypeOptions"
        >
        </product-code-manager>
      </form-field>

      <div class="separator"></div>

      <form-field label="tags" description="tags" [readonly]="readonly">
        <lxm-select
          select
          for="tags"
          labelField="value"
          compareField="id"
          [readonly]="readonly"
          [searchable]="true"
          [multiple]="true"
          [options]="tagsOptions"
        >
        </lxm-select>
        <validation-errors for="tags"></validation-errors>
      </form-field>

      <form-field
        label="attributes"
        description="attributes"
        [readonly]="readonly"
        class="mb0"
      >
        <lxm-select
          select
          for="attributes"
          labelField="translatedValue"
          [options]="attributeOptions"
          [readonly]="readonly"
          [multiple]="true"
          [isTranslated]="true"
          [searchable]="true"
          [sortByLabel]="true"
        >
        </lxm-select>
        <validation-errors for="attributes"></validation-errors>
      </form-field>

      <div class="separator"></div>

      <form-field
        for="parameter1"
        type="input-text"
        label="parameter1"
        inputClass="w60p"
        [readonly]="readonly"
        *ngIf="isParameterVisible('parameter1')"
        description="parameter1"
      >
        <validation-errors for="parameter1"></validation-errors>
      </form-field>

      <form-field
        for="parameter2"
        type="input-text"
        label="parameter2"
        inputClass="w60p"
        [readonly]="readonly"
        *ngIf="isParameterVisible('parameter2')"
        description="parameter2"
      >
        <validation-errors for="parameter2"></validation-errors>
      </form-field>

      <form-field
        for="parameter3"
        type="input-text"
        label="parameter3"
        inputClass="w60p"
        [readonly]="readonly"
        *ngIf="isParameterVisible('parameter3')"
        description="parameter3"
      >
        <validation-errors for="parameter3"></validation-errors>
      </form-field>

      <form-field
        for="parameter4"
        type="input-text"
        label="parameter4"
        inputClass="w60p"
        [readonly]="readonly"
        *ngIf="isParameterVisible('parameter4')"
        description="parameter4"
      >
        <validation-errors for="parameter4"></validation-errors>
      </form-field>

      <form-field
        for="parameter5"
        type="input-text"
        label="parameter5"
        inputClass="w60p"
        [readonly]="readonly"
        *ngIf="isParameterVisible('parameter5')"
        description="parameter5"
      >
        <validation-errors for="parameter5"></validation-errors>
      </form-field>

      <form-field
        for="parameter6"
        type="input-text"
        label="parameter6"
        inputClass="w60p"
        [readonly]="readonly"
        *ngIf="isParameterVisible('parameter6')"
        description="parameter6"
      >
        <validation-errors for="parameter6"></validation-errors>
      </form-field>

      <form-field
        for="parameter7"
        type="input-text"
        label="parameter7"
        inputClass="w60p"
        [readonly]="readonly"
        *ngIf="isParameterVisible('parameter7')"
        description="parameter7"
      >
        <validation-errors for="parameter7"></validation-errors>
      </form-field>

      <form-field
        for="parameter8"
        type="input-text"
        label="parameter8"
        inputClass="w60p"
        [readonly]="readonly"
        *ngIf="isParameterVisible('parameter8')"
        description="parameter8"
      >
        <validation-errors for="parameter8"></validation-errors>
      </form-field>

      <form-field
        for="parameter9"
        type="input-text"
        label="parameter9"
        inputClass="w60p"
        [readonly]="readonly"
        *ngIf="isParameterVisible('parameter9')"
        description="parameter9"
      >
        <validation-errors for="parameter9"></validation-errors>
      </form-field>

      <form-field
        for="parameter10"
        type="input-text"
        label="parameter10"
        inputClass="w60p"
        [readonly]="readonly"
        *ngIf="isParameterVisible('parameter10')"
        description="parameter10"
      >
        <validation-errors for="parameter10"></validation-errors>
      </form-field>

      <lxm-button
        label="cards.offer.retailer_details.label.add_parameter"
        type="transparent"
        class="ml15"
        *ngIf="!readonly && isAddParameterButtonVisible"
        [icon]="'plus-dark'"
        (click)="addParameter()"
      >
      </lxm-button>

      <!-- <div class="separator"></div> -->

      <table class="mt30 mb30">
        <thead>
          <tr>
            <th style="width: 33.3333%">
              {{'cards.offer.retailer_details.label.expiry_in_days' |
              translate}}
            </th>
            <th style="width: 33.3333%">
              {{'cards.offer.retailer_details.label.min_expiry_on_delivery' |
              translate}}
            </th>
            <th style="width: 33.3333%">
              {{'cards.offer.retailer_details.label.expiry_for_target_in_days' |
              translate}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <lxm-input
                input
                for="expiryInDays"
                [maxlength]="4"
                [readonly]="readonly"
              ></lxm-input>
            </td>
            <td>
              <lxm-input
                input
                for="minExpiryOnDelivery"
                [maxlength]="4"
                [readonly]="readonly"
              ></lxm-input>
            </td>
            <td>
              <lxm-input
                input
                for="expiryForTargetInDays"
                [maxlength]="4"
                [readonly]="readonly"
              ></lxm-input>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- <div class="separator"></div> -->

      <!-- <form-field for="expiryInDays" label="expiry_in_days" [readonly]="readonly" description="expiry_in_days">
        <lxm-input input for="expiryInDays" [readonly]="readonly" [maxlength]="4"></lxm-input>
        <validation-errors for="expiryInDays"></validation-errors>
      </form-field>

      <form-field for="minExpiryOnDelivery" label="min_expiry_on_delivery" [readonly]="readonly" description="min_expiry_on_delivery">
        <lxm-input input for="minExpiryOnDelivery" [maxlength]="4" [readonly]="readonly"></lxm-input>
        <validation-errors for="minExpiryOnDelivery"></validation-errors>
      </form-field>

      <form-field for="expiryForTargetInDays" label="expiry_for_target_in_days" [readonly]="readonly" description="expiry_for_target_in_days">
        <lxm-input input for="expiryForTargetInDays" [maxlength]="4" [readonly]="readonly"></lxm-input>
        <validation-errors for="expiryForTargetInDays"></validation-errors>
      </form-field> -->

      <form-field
        [readonly]="readonly"
        labelTop="true"
        label="description"
        for="description"
        description="description"
      >
        <language-text
          language-text
          for="description"
          [newline]="true"
          [readonly]="readonly"
        ></language-text>
      </form-field>
    </div>
  </scope>

  <div footer>
    <div class="f-1 flex flex-row justify-between">
      <div class="flex flex-row">
        <lxm-button
          label="action.save"
          type="primary"
          buttonType="submit"
          *ngIf="!readonly"
          [loading]="isSaveLoading"
          (click)="save()"
        ></lxm-button>
        <lxm-button
          label="action.cancel"
          type="secondary"
          [class.ml15]="!readonly"
          (click)="closeDialog()"
        ></lxm-button>
      </div>
      <div class="flex flex-row">
        <lxm-button
          label="action.retailer_details_copy"
          type="transparent"
          *ngIf="!readonly"
          icon="copy"
          (click)="openRetailerDetailsCopyDialog()"
        ></lxm-button>
      </div>
    </div>
  </div>
</lxm-dialog>
