<lxm-dialog [dialogRef]="dialogRef" [formGroup]="form" #dialog>
  <span header-title [ngSwitch]="canEdit">
    <ng-container *ngSwitchCase="true">
      {{ 'cards.products.product_packaging.title_edit' | translate }}
    </ng-container>
    <ng-container *ngSwitchCase="false">
      {{ 'cards.products.product_packaging.title' | translate }}
    </ng-container>
  </span>
  <div body style="padding-bottom: 40px">
    <div class="row nowrap">
      <div class="col-6 no-padding">
        <form-field
          dialog
          [readonly]="!canEdit || type?.id === ProductUnitType.BaseUnit"
          for="unitType"
          description="cards.products.product_packaging_edit_basic_unit.label_description.unit_type"
        >
          <span label
            >{{'cards.products.product_packaging_edit_basic_unit.label.unit_type'
            | translate}}</span
          >
          <span text *ngIf="type?.id === ProductUnitType.BaseUnit"
            >{{ baseUnitType | systemClvName }}</span
          >
          <div
            class="f-1"
            any
            row
            *ngIf="type?.id !== ProductUnitType.BaseUnit"
          >
            <lxm-select
              for="unitType"
              [options]="selectableUnitTypes"
              value="id"
              [clearable]="false"
              labelField="translatedValue"
              [isTranslated]="true"
              (change)="selectUnitType($event)"
            >
            </lxm-select>
          </div>
        </form-field>
      </div>
      <div class="col-6 no-padding">
        <form-field
          dialog
          [readonly]="!canEdit"
          label="global.status"
          description="cards.products.product_packaging_edit_basic_unit.label_description.unit_status"
          *ngIf="type?.id !== ProductUnitType.BaseUnit"
        >
          <slide-toggle
            toggle
            for="isActive"
            yes="cards.products.product_packaging_edit_pallet.label.unit_status.yes"
            no="cards.products.product_packaging_edit_pallet.label.unit_status.no"
          >
          </slide-toggle>
        </form-field>
      </div>
    </div>

    <ng-container [ngSwitch]="type?.id">
      <packaging-dialog-basic-unit
        *ngSwitchCase="ProductUnitType.BaseUnit"
      ></packaging-dialog-basic-unit>
      <packaging-dialog-bulk-packaging
        *ngSwitchCase="ProductUnitType.InnerBoxUnit"
      >
      </packaging-dialog-bulk-packaging>
      <packaging-dialog-bulk-packaging
        *ngSwitchCase="ProductUnitType.InnerBoxUnitSecond"
      >
      </packaging-dialog-bulk-packaging>
      <packaging-dialog-bulk-packaging
        *ngSwitchCase="ProductUnitType.TransportUnit"
      >
      </packaging-dialog-bulk-packaging>
      <packaging-dialog-pallet
        *ngSwitchCase="ProductUnitType.Pallet"
      ></packaging-dialog-pallet>
      <packaging-dialog-display
        *ngSwitchCase="ProductUnitType.Display"
      ></packaging-dialog-display>
    </ng-container>
  </div>

  <div footer>
    <lxm-button
      *ngIf="canEdit"
      type="primary"
      label="cards.products.product_file_management.save_file"
      [loading]="saveLoading"
      (click)="saveUnit()"
      [disabled]="!type?.id"
      buttonType="submit"
    >
    </lxm-button>
    <lxm-button
      type="secondary"
      class="ml10"
      label="cards.products.product_file_management.cancel_file"
      (click)="closeDialog()"
    >
    </lxm-button>
  </div>
</lxm-dialog>
