import { Component, EventEmitter, Host, Input, Optional, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { FormGroup, ControlContainer } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ScopeComponent } from "src/app/components/_layout/card/card.component";
import { ReadonlyDirective } from "src/app/directives";
import { FieldRequiredType } from "src/app/enum";

@Component({
  selector: "description-popover",
  templateUrl: "./description-popover.component.html",
  styleUrls: ['./description-popover.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DescriptionPopoverComponent {

  @Input() public readonly = false;
  @Input() public description: string;

  @Input() public position: string = 'auto';
  @Input() public trigger: string = 'click';

}
