<ng-container [ngSwitch]="readonly" [formGroup]="formGroup">
  <mat-form-field combined [style.width]="width" *ngSwitchCase="false">
    <input
      matInput
      input
      [min]="min"
      [max]="max"
      [formControlName]="for"
      [format]="format"
      [id]="inputId || for"
      [placeholder]="placeholder | translate"
      [matDatepicker]="picker"
      autocomplete="off"
      (blur)="blur.emit($event)"
      [attr.disabled]="disabled ? true : undefined"
    />
    <mat-datepicker
      #picker
      (closed)="blur.emit($event)"
      [attr.disabled]="disabled ? true : undefined"
    ></mat-datepicker>
    <mat-datepicker-toggle
      matSuffix
      suffix
      [for]="picker"
      tabindex="-1"
      *ngIf="!disabled"
    >
      <span
        class="date-small-icon cursor-pointer"
        matDatepickerToggleIcon
      ></span>
    </mat-datepicker-toggle>
    <ng-content select="validation-errors"></ng-content>

  </mat-form-field>
  <span class="lxm-input datepicker-readonly w-full" *ngSwitchCase="true">
    {{ formGroup.get(for)?.value | formDate: "L" : offset }}
  </span>
</ng-container>
