<card-expanding [formGroup]="form" [valid]="cardValidType" [expanded]="isExpanded" (onExpand)="saveLocalExpandedValue($event)">

  <span header-title>{{ title | translate}}</span>

  <div header-actions *requiredRights="[UserAction.ManageProducts]">
    <ng-container *ngIf="isOwnProduct">
      <ng-container *ngIf="!locked">
        <lxm-button *ngIf="!edit" type="beige" icon="edit" title="action.edit" (click)="toggleEdit()"></lxm-button>
        <lxm-button *ngIf="edit" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()">
        </lxm-button>
        <lxm-button *ngIf="edit" type="beige" icon="save" label="action.save" [loading]="saveLoading"
          (click)="save()" buttonType="submit"></lxm-button>
      </ng-container>
      <ng-container *ngIf="locked">
        {{ 'global.locked_by' | translate }}: {{ lockedBy.name }} {{ lockedAt | amTimeAgo }}
      </ng-container>
    </ng-container>
  </div>

  <div body>

    <div class="row">
      <div class="col-6 no-padding">

         <!-- description="cards.products.product_music_info.label_description.music_format" -->
        <form-field [readonly]="!edit"
          label="cards.products.product_music_info.label.music_format"
          for="musicFormatId"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MusicFormat, form.get('musicFormatId').value) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="musicFormatOptions"
            for="musicFormatId"
            value="id"
            labelField="translatedValue"
            [isTranslated]="true"
            [sortByLabel]="true">
            </lxm-select>
          <validation-errors for="musicFormatId"></validation-errors>
        </form-field>

         <!-- description="cards.products.product_music_info.label_description.genres" -->
        <form-field [readonly]="!edit"
          label="cards.products.product_music_info.label.genres"
          for="genres"
          [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MusicGenres, form.get('genres').value?.length > 0) : null">
          <lxm-select select
            [readonly]="!edit"
            [options]="musicGenreOptions"
            for="genres"
            value="id"
            labelField="translatedValue"
            maxSelectedItems="4"
            [multiple]="true"
            [searchable]="true"
            [isTranslated]="true"
            [sortByLabel]="true">
          </lxm-select>
          <validation-errors for="genres"></validation-errors>
        </form-field>

        <!-- description="cards.products.product_music_info.label_description.disc_amount" -->
        <form-field [readonly]="!edit" for="discAmount"
            label="cards.products.product_music_info.label.disc_amount"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MusicDiscAmount, form.get('discAmount').value) : null">
            <validation-errors for="discAmount"></validation-errors>
        </form-field>

         <!-- description="cards.products.product_music_info.label_description.isOnParentAgreement" -->
        <form-field [readonly]="!edit" label="cards.products.product_music_info.label.isOnParentAgreement">
          <slide-toggle toggle for="isOnParentAgreement" [readonly]="!edit"></slide-toggle>
        </form-field>

      </div>

            <!-- description="cards.products.product_music_info.label_description.year" -->

      <div class="col-6 no-padding">
        <form-field [readonly]="!edit" for="year"
            label="cards.products.product_music_info.label.year"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MusicYear, form.get('year').value) : null">
            <validation-errors for="year"></validation-errors>
        </form-field>

            <!-- description="cards.products.product_music_info.label_description.release_year" -->

        <form-field [readonly]="!edit" for="releaseYear"
            label="cards.products.product_music_info.label.release_year"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MusicReleaseYear, form.get('releaseYear').value) : null">
            <validation-errors for="releaseYear"></validation-errors>
        </form-field>

            <!-- description="cards.products.product_music_info.label_description.publisher" -->

        <form-field [readonly]="!edit" for="publisher"
            label="cards.products.product_music_info.label.publisher"
            type="input-text"
            [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MusicPublisher, form.get('publisher').value) : null">
            <validation-errors for="publisher"></validation-errors>
        </form-field>

      </div>
    </div>

    <section-expanding [expanded]="true" [required]="productValidity.shouldValidate ? productValidity.isRequiredByRetailer(cardSection, productField.MusicTrackLists, form.get('tracks').value?.length > 0) : null">
      <span header-title>
        {{'cards.products.product_music_info.track_section.section_title' | translate}}
      </span>
      <div body class="w-full overflow-x">
          <music-track-list for="tracks" [readonly]="!edit"></music-track-list>
      </div>
    </section-expanding>

  </div>

  <div footer class="flex flex-row" *ngIf="edit">
    <lxm-button class="ml15" type="grey" icon="back" label="action.cancel" (click)="toggleEdit()"></lxm-button>
    <lxm-button class="ml15" type="beige" icon="save" label="action.save" [loading]="saveLoading" (click)="save()">
    </lxm-button>
  </div>

</card-expanding>
