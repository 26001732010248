<lxm-dialog [dialogRef]="dialogRef" #dialog [disableContentMargin]="true">
  <div header-title class="flex flex-col gap-1">
    <span class="dialog-label">
      {{'cards.purchase_manager_desktop.products_list.table.assortment_and_prices'
      | translate}}
    </span>
    <span class="product-name">
      {{productName | translatedValue:false:''}}
    </span>
  </div>

  <scope body class="dialog-column-wrapper row">
    <div class="dialog-column-left col-4 col-md-4">
      <section class="product-info-section" [formGroup]="formGroup">
        <div class="f-0 flex" style="position: absolute">
          <image-box
            class="main-image"
            [images]="[productImage]"
            [index]="0"
            imageFit="contain"
            size="3"
          >
          </image-box>
        </div>
        <div class="f-1 flex flex-col">
          <form-field class="mb0">
            <div any class="nowrap input-padding align-center flex flex-row">
              <status-circle
                class="mr5"
                [status]="product?.assortmentStatus"
                enum="SupplierProductAssortmentStatus"
              ></status-circle>
              <span class="product-header-text">
                {{product?.assortmentStatus | translateEnum:
                'SupplierProductAssortmentStatus'}}
              </span>
            </div>
          </form-field>
          <form-field class="mb0">
            <div any class="input-padding flex flex-col">
              <span class="product-header-label"
                >{{'table.barcode' | translate}}</span
              >
              <span class="product-header-text">{{product.ean || '-'}}</span>
            </div>
          </form-field>
          <form-field class="mb0">
            <div any class="input-padding flex flex-col">
              <span class="product-header-label"
                >{{'enums.ProductUnitCodeType.SupplierCode' | translate}}</span
              >
              <span class="product-header-text"
                >{{product.supplierCode || '-'}}</span
              >
            </div>
          </form-field>

          <form-field
            [readonly]="readonly"
            label="table.supplier"
            description="cards.offer.assortment_and_prices_dialog.label_description.supplier"
          >
            <lxm-select
              select
              for="supplier"
              [options]="supplierOptions"
              [readonly]="true"
            ></lxm-select>
          </form-field>
          <form-field
            [readonly]="readonly"
            label="table.logistics_service"
            description="cards.offer.assortment_and_prices_dialog.label_description.logistics_service"
          >
            <lxm-select
              select
              for="logisticsPartner"
              [readonly]="readonly"
              [options]="logisticsPartnerOptions"
              labelField="value"
            ></lxm-select>
          </form-field>
          <form-field [readonly]="readonly" label="table.currency">
            <lxm-select
              select
              for="currencies"
              [readonly]="readonly || salesCurrencies?.length < 2"
              [multiple]="true"
              [options]="salesCurrencies"
              labelField="value"
            ></lxm-select>
            <!-- [multiple]="salesCurrencies?.length > 1" -->
          </form-field>
          <form-field
            [readonly]="readonly"
            label="cards.offer.assortment_and_prices_dialog.label.vat"
            description="cards.offer.assortment_and_prices_dialog.label_description.vat"
          >
            <lxm-select
              select
              for="vat"
              labelField="value"
              [searchable]="true"
              [sortByLabel]="true"
              [options]="vatOptions"
              [readonly]="readonly"
              compareField="id"
            >
              <validation-errors for="vatId" aliasTo="vat"></validation-errors>
            </lxm-select>
          </form-field>
          <form-field
            [readonly]="readonly"
            label="cards.offer.assortment_and_prices_dialog.label.product_payment"
            description="cards.offer.assortment_and_prices_dialog.label_description.product_payment"
          >
            <product-payment-manager
              class="f-1 flex"
              any
              [readonly]="readonly"
              [formGroup]="formGroup"
              [currency]="offerCurrency"
              [suppliersOptions]="retailerSuppliers"
              [disabled]="!isAssortmentStatusSelected"
            >
            </product-payment-manager>
          </form-field>
        </div>
      </section>
    </div>

    <!-- PRICES SECTION -->

    <div class="dialog-column-center col-4 col-md-4">
      <offer-product-supplier-prices
        [readonly]="readonly"
        [formGroup]="formGroup"
        [data]="data"
        [formData]="priceSchemeFormData"
        [currencies]="selectedCurrencies"
        [currencyOptions]="selectedCurrencies"
        [defaultCurrency]="buyInPriceCurrency"
        [priceSchemeData]="priceSchemeData"
        [markupMethod]="markupMethod"
        [buyInPriceCurrencyRetailPrice]="buyInPriceCurrencyRetailPrice"
      >
      </offer-product-supplier-prices>
    </div>

    <!-- ASSORTMENT SECTION -->
    <div class="dialog-column-right col-4 col-md-4">
      <offer-product-assortment
        [readonly]="readonly"
        [formGroup]="formGroup"
        [data]="data"
        [formData]="assortmentFormData"
        [assortmentData]="assortmentData"
        [displayProduct]="displayProduct"
      ></offer-product-assortment>
    </div>
  </scope>

  <div footer class="justify-between">
    <div class="flex flex-row">
      <lxm-button
        type="primary"
        label="action.save"
        (click)="save()"
        [loading]="actionLoading.save"
        *ngIf="!readonly"
      ></lxm-button>
      <lxm-button
        type="secondary"
        [class.ml10]="!readonly"
        label="action.cancel"
        (click)="closeDialog(null)"
      ></lxm-button>
    </div>

    <!-- <div>
            <lxm-button (click)="deleteAssortment()"
                *ngIf="!readonly && hasAssortmentId"
                type="transparent"
                label="action.delete"
                icon="trash"
                class="ml30"
                [loading]="isDeleteLoading"></lxm-button>
        </div> -->
  </div>
</lxm-dialog>
